// audio store
import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import videoStore, {videoStoreApi} from "../videos/videoStore";
import {sceneStoreApi} from "../scenes/sceneStore";
import {experienceStoreApi} from "../../experience/components/three/SceneManager";
import {controlsStoreApi} from "../../experience/components/three/controls/CControls";
import {doActions} from "../actionBuilderProcessing";

const [boomerangStore, boomerangStoreAPI] = create((set, get) => ({
    destination: 0,
    cameraOrientation: null,
    events: null,
    allBoomerangs: [],
    actions: {
        setAllBoomerangs(boomerangs) {
            set(state => ({ allBoomerangs: processBoomerangs(boomerangs) }))
            // console.log('', get().allBoomerangs)
        },
        playBoomerang(id) {
            let boomerang = getValueByKey(get().allBoomerangs, "id", id)
            console.log('play boomerang', boomerang);

            /**
             * Play boomerang
             */

            // set video
            // console.log('boomerang.cameraOrientation', boomerang.cameraOrientation);
            set(state => ({ boomerangVideo: boomerang.boomerangVideo }))
            set(state => ({ cameraOrientation: boomerang.cameraOrientation }))
            set(state => ({ events: boomerang.events }))

            videoStore.getState().actions.playBoomerang(
                boomerang
            )

            experienceStoreApi.getState().actions.setIs2d(true)
            document.body.classList.add("is2d")

            // ACTION
            if (get().events && get().events.EVENT_START) {
                doActions(get().events.EVENT_START.actions)
            }
        },
        onEnd() {
            console.log('--------------------------------------------------', );
            // console.log('ended', get().destination);
            // sceneStoreApi.getState().actions.setScene(get().destination)
            sceneStoreApi.getState().actions.endBoomerang()
            experienceStoreApi.getState().actions.setIs2d(false)
            document.body.classList.remove("is2d")

            // camera focus

            if (get().cameraOrientation && get().cameraOrientation.x.length) {
                controlsStoreApi.getState().setTarget(get().cameraOrientation, true)
            }

            // ACTION
            if (get().events && get().events.EVENT_END) {
                // console.log('', get().events);

                setTimeout(()=> {
                    doActions(get().events.EVENT_END.actions)
                }, 60)
                // doActions(get().events.EVENT_END.actions)
            }
        },
        doSkip() {
            if (get().events && get().events.EVENT_SKIP) {
                // console.log('', get().events);
                doActions(get().events.EVENT_SKIP.actions)
            }
        }
    }
}))
export default boomerangStore
export {boomerangStoreAPI}

function processBoomerangs(boomerangs) {
    let processedBoomerangs = []
    boomerangs.forEach(boomerang => {
        // console.log('', boomerang);
        let processedBoomerang = {}
        processedBoomerang.id = boomerang.id
        processedBoomerang.title = boomerang.title
        processedBoomerang.boomerangSkippable = boomerang.boomerangSkippable
        processedBoomerang.boomerangVideo = boomerang.boomerangVideo[0].id
        processedBoomerang.cameraOrientation = boomerang.cameraOrientation ? boomerang.cameraOrientation[0] : null
        processedBoomerang.events = boomerang.events.length ? processEvents(boomerang.events) : null
        processedBoomerangs.push(processedBoomerang)

        // Add videos to preload
        if (processedBoomerang.boomerangVideo) {
            // console.log('processedBoomerang.routeVideo', processedBoomerang.boomerangVideo);
            videoStoreApi.getState().actions.addActiveVideo(processedBoomerang.boomerangVideo)
        }
    })
    return processedBoomerangs
}


function processEvents(events) {
    // console.log('', events);
    let processedEvents = {}
    events.forEach(event => {
        switch (event.event) {
            case "boomerangEnd":
                // console.log('', event);
                processedEvents.EVENT_END =  event.eventActions[0]
                break
            case "boomerangStart":
                // console.log('', event);
                processedEvents.EVENT_START =  event.eventActions[0]
                break
            case "boomerangSkip":
                // console.log('', event);
                processedEvents.EVENT_SKIP =  event.eventActions[0]
                break
            default:
        }
    })
    return processedEvents
}
