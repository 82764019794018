import {sceneStoreApi} from "../store/scenes/sceneStore";
import {useNavigate} from "react-router-dom";
import {hotspotStoreApi} from "../store/hotspots/hotspotStore";
import {iconStoreApi} from "../store/icons/iconsStore";
import {audioStoreApi} from "../store/audio/audioStore";
import globalSetStore, {globalSetStoreAPI} from "../store/globalSets/globalSetStore";
import Interweave from "interweave";
import {useEffect} from "react";
import themeStore from "../store/themes/themeStore";
import "./_pagehome.scss"
import {trackEvent, TrackingLabels} from "../tracking/Tracking";

/**
 * This is the home page
 * @returns {JSX.Element}
 * @constructor
 */
function PageHome() {

    const navigate = useNavigate();
    const globalSets = globalSetStore(state => state.globalSets)
    const theme = themeStore(state => state.theme)

    useEffect(() => {
        // console.log('globalSets', globalSets);

    }, [globalSets])

    function onClick() {
        // const v = videoActions.getVideoByID('613');
        const v = hotspotStoreApi.getState().actions.getHotspotByID('1956');
        console.log('hotspot', v);
    }

    function onClick2() {
        // const v = videoActions.getVideoByID('613');
        const v = sceneStoreApi.getState().actions.getScene('2596');
        console.log('scene', v);
    }

    function onClick3() {
        const v = iconStoreApi.getState().actions.getIconByID('2310')
        console.log('icon', v);
    }

    function onClick4() {
        const v = audioStoreApi.getState().actions.getAudioByID('3292')
        console.log('audio', v);
    }

    function onStart() {
        navigate('/experience')

        // tracking
            trackEvent(
                {
                    name: TrackingLabels.names.BUTTON_CLICK,
                    category: TrackingLabels.categories.LANDING_PAGE,
                    label: 'enter'
                }
            )
        // end tracking
    }

    return (
        <div className="page">
            {
                theme.logo ?
                    <div className="ui__icon ui__logo">
                        <img src={theme.logo}/>
                    </div>
                    : null
            }
                {
                    globalSets ?
                        <>
                            <div className="page__home">
                                <Interweave content={globalSets.landingPageCopy}/>

                                <div className="uibutton home__startbutton"
                                     style={theme.buttonStyle}
                                     onClick={onStart}
                                >{globalSets.landingPageButton}</div>
                                <div className="terms">
                                    <Interweave content={globalSets.landingPageTerms}/>
                                </div>
                            </div>
                            {/*<div className="page__darken" />*/}
                            <div className="page__background"
                                 style={{backgroundImage: `url(${globalSets.landingPageBackground.url})`}}/>
                        </>
                        : null
                }
            {/*<h1>PAGE HOME</h1>
            <h3>Load data</h3>
            <ul>
                <li>Create scenes</li>
                <li>Create routes</li>
                <li>Create modals</li>
                <li>Create collectibles</li>
            </ul>
            <p>Do speed test</p>
            <p className="secondary">Preload assets and videos - show preloader</p>
            <button onClick={onClick}>Get hotspot by ID</button>
            <button onClick={onClick2}>Get scene by ID</button>
            <button onClick={onClick3}>Get icon by ID</button>
            <button onClick={onClick4}>Get audio by ID</button>
            <button onClick={onStart}>Start</button>*/}
        </div>
    )
}

export default PageHome
