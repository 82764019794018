// theme store
import create from "zustand";
import {getValueByKey, hexToRGB} from "../../utils/utilities";
import icon_gyro from "../../assets/icons/svg/gyro.svg";
import icon_fullscreen from "../../assets/icons/svg/fullscreen.svg";
import icon_sound from "../../assets/icons/svg/sound_on.svg";
import icon_hamburger from "../../assets/icons/svg/hamburger.svg";
import icon_stamp from "../../assets/icons/svg/stamp.svg";
import {jss} from "react-jss";

const [themeStore, themeStoreAPI] = create((set, get) => ({
    destination: 0,
    theme: {
        colours: {
            primary: "#ffffff",
            secondary: "#000000",
            highlight: "#000000"
        },
        borderRadius: "22px",
        buttonStyle: {
            backgroundColor: "pink",
            color: "blue",
            borderRadius: "30px"
        },
        logo: null,
        icons: {
            fullScreen: {
                asset: icon_fullscreen,
                iconPosition: "bl"
            },
            gyro: {
                asset: icon_gyro,
                iconPosition: "bl"
            },
            menu: {
                asset: icon_hamburger,
                iconPosition: "tr"
            },
            sound: {
                asset: icon_sound,
                iconPosition: "br"
            },
            stamp: {
                asset: icon_stamp,
                iconPosition: "tl"
            }
        }
    },
    allThemes: [],
    actions: {
        setAllThemes(themes) {
            set(state => ({allThemes: processThemes(themes)}))
            // console.log('', get().allThemes)
        },
        setSelectedTheme(id) {

            // console.log('setting selected theme', id);

            let theme = getValueByKey(get().allThemes, "id", id)

            loadFonts(theme.font)
            // console.log('setting', theme);

            set(state => ({
                theme: {
                    colours: {
                        primary: theme.colours.primary,
                        secondary: theme.colours.secondary,
                        highlight: theme.colours.highlight,
                        modalBackground: theme.colours.colourModalBackground,
                    },
                    buttonStyle: {
                        backgroundColor: theme.colours.primary,
                        color: theme.colours.secondary,
                        borderRadius: theme.buttonRadius
                    },
                    buttonStyleSecondary: {
                        // backgroundColor: theme.colours.secondary,
                        color: theme.colours.primary,
                        borderRadius: theme.buttonRadius,
                        borderColor: theme.colours.primary
                    },
                    modalStyle: {
                        borderRadius: theme.borderRadius,
                        backgroundColor: theme.colours.modalBackground
                    },
                    icons: theme.icons,
                    logo: `${theme.logo ? theme.logo : null}`
                }
            }))
            setTimeout(() => {
                setColours(get().allThemes[0])
            }, 1160)
            const sheet = jss
                .createStyleSheet(
                    {
                        theme: (data) => ({
                            '& .col-primary': {
                                backgroundColor: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                                color: hexToRGB(theme.colours.primary, theme.colours.primaryOpacity)
                            },
                            '& .col-secondary': {
                                backgroundColor: hexToRGB(theme.colours.primary, theme.colours.primaryOpacity),
                                color: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity)
                            },
                            '& .col-highlight': {
                                backgroundColor: hexToRGB(theme.colours.highlight, theme.colours.highlightOpacity)
                            },
                            '& .col-menu': {
                                backgroundColor: hexToRGB(theme.colours.primary, theme.colours.primaryOpacity),
                                color: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                            },
                            '& .menu__line': {
                                borderTop: `0.5px solid ${hexToRGB(theme.colours.secondary, 0.3)}`
                            },
                            '& .menu__item:after': {
                                backgroundColor: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity)
                            },
                            '& .menu__item--active:after': {
                                backgroundColor: hexToRGB(theme.colours.highlight, theme.colours.highlightOpacity)
                            },
                            '& .helpcircle': {
                                borderColor: hexToRGB(theme.colours.primary, theme.colours.primaryOpacity),
                                backgroundColor: "none"
                            },
                            '& .helpcircle--active': {
                                backgroundColor: hexToRGB(theme.colours.primary, theme.colours.primaryOpacity),
                            },
                            '& .dark .helpcircle': {
                                borderColor: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                                backgroundColor: "none"
                            },
                            '& .dark .helpcircle--active': {
                                backgroundColor: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                            },
                            '& .icon-primary': {
                                fill: hexToRGB(theme.colours.primary, theme.colours.primaryOpacity),
                            },
                            '& .col-secondary .icon-primary': {
                                fill: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                            },
                            '& .icon-secondary': {
                                fill: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                            },
                            '& .col-secondary .icon-secondary': {
                                fill: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                            },
                            '& .fullvideo .icon-secondary': {
                                fill: hexToRGB(theme.colours.secondary, theme.colours.secondaryOpacity),
                            },
                            '& .modaldev__panel h1': {
                                textTransform: theme.capitaliseModalTitles ? "uppercase" : "none",
                            },
                            '& .modaldev__panel': {
                                borderRadius: theme.borderRadius
                            },
                            '& .uibutton__secondary': {
                                boxShadow: `inset 0 0 0 1px ${theme.colours.primary}`,
                            },
                            '& .uibutton__secondary:hover': {
                                boxShadow: `inset 0 0 0 3px ${theme.colours.primary}`,
                            },
                            '& .uibutton': {
                                textTransform: theme.capitaliseButtons ? "uppercase" : "none",
                                borderWidth: theme.buttonBorder ? "4px" : "0px"
                            },
                            '& h1': {
                                fontSize: theme.modalTitleFontSize ? `${theme.modalTitleFontSize}rem` : "1.5rem",
                                color: theme.modalTitleColour ? `${theme.modalTitleColour} !important` : null,
                            },
                            '& .col-secondary h1': {
                                color: theme.modalTitleColourDark ? `${theme.modalTitleColourDark} !important` : null,
                            },
                            '& .col-secondary .swiper-button-next:after': {
                                color: `${theme.colours.secondary} !important`
                            },
                            '& .col-secondary .swiper-button-prev:after': {
                                color: `${theme.colours.secondary} !important`
                            },
                            '& .col-secondary .uibutton__secondary': {
                                borderColor: `${theme.colours.secondary} !important`,
                                color: `${theme.colours.secondary} !important`,
                                backgroundColor: `none !important`,
                            },
                            '& .col-secondary .uibutton__primary': {
                                backgroundColor: `${theme.colours.secondary} !important`,
                                color: `${theme.colours.primary} !important`,
                            },
                            '& .col-secondary .uibutton__secondary:hover': {
                                boxShadow: `inset 0 0 0 3px ${theme.colours.secondary} !important`,
                            },
                            '& .ui__icon': {
                                opacity: `${theme.iconsOpacity}`,
                            },
                            '& .page__home p': {
                                color: hexToRGB(theme.colours.landingPageColour, theme.colours.landingPageOpacity),
                            },
                            '& .menu__content p': {
                                fontSize: theme.bodyTextSize
                            }
                        })
                    },
                    {link: true}
                )
                .attach()
            sheet.update()
            document.body.classList.add(sheet.classes.theme)
        }
    }
}))

export default themeStore
export {themeStoreAPI}

function processThemes(themes) {
    let processedThemes = []
    themes.forEach(theme => {
        let processedTheme = {}
        processedTheme.id = theme.id
        processedTheme.title = theme.title
        processedTheme.borderRadius = theme.borderRadius
        processedTheme.buttonRadius = theme.buttonRadius
        processedTheme.buttonBorder = theme.buttonBorder
        processedTheme.capitaliseModalTitles = theme.capitaliseModalTitles
        processedTheme.capitaliseButtons = theme.capitaliseButtons
        processedTheme.modalTitleColour = theme.modalTitleColour
        processedTheme.modalTitleColourDark = theme.modalTitleColourDark
        processedTheme.modalTitleFontSize = theme.modalTitleFontSize
        processedTheme.iconsOpacity = theme.iconsOpacity
        processedTheme.bodyTextSize = theme.bodyTextSize
        processedTheme.logo = theme.logo.length && theme.logo[0].url

        processedTheme.colours = {
            primary: theme.colourPrimary.length && theme.colourPrimary[0].colourValue,
            primaryOpacity: theme.colourPrimary.length && theme.colourPrimary[0].colourOpacity,
            secondary: theme.colourSecondary.length && theme.colourSecondary[0].colourValue,
            secondaryOpacity: theme.colourSecondary.length && theme.colourSecondary[0].colourOpacity,
            highlight: theme.colourHighlight.length && theme.colourHighlight[0].colourValue,
            highlightOpacity: theme.colourHighlight.length && theme.colourHighlight[0].colourOpacity,
            landingPageColour: theme.landingPageColour.length && theme.landingPageColour[0].colourValue,
            landingPageOpacity: theme.landingPageColour.length && theme.landingPageColour[0].colourOpacity,
        }
        /**
         * fonts
         */
        processedTheme.font = {
            regular: theme.bodyFont.length ? theme.bodyFont[0].url : null,
            bold: theme.boldFont.length ? theme.boldFont[0].url : null,
            extraBold: theme.extraBoldFont.length ? theme.extraBoldFont[0].url : null,
        }

        /**
         * Icons
         */
        processedTheme.icons = {
            fullScreen: {
                asset: theme.iconFullscreen.length && theme.iconFullscreen[0].iconAsset[0].url,
                iconPosition: theme.iconFullscreen.length && theme.iconFullscreen[0].iconPosition,
                enabled: theme.iconFullscreen.length &&theme.iconFullscreen[0].featureEnabled
            },
            gyro: {
                asset: theme.iconGyro.length && theme.iconGyro[0].iconAsset[0].url,
                iconPosition: theme.iconGyro.length && theme.iconGyro[0].iconPosition,
                enabled: theme.iconGyro.length &&theme.iconGyro[0].featureEnabled
            },
            menu: {
                asset: theme.iconMenu.length && theme.iconMenu[0].iconAsset[0].url,
                iconPosition: theme.iconMenu.length && theme.iconMenu[0].iconPosition,
                enabled: theme.iconMenu.length && theme.iconMenu[0].featureEnabled
            },
            sound: {
                asset: theme.iconSound.length && theme.iconSound[0].iconAsset[0].url,
                iconPosition: theme.iconSound.length && theme.iconSound[0].iconPosition,
                enabled: theme.iconSound.length && theme.iconSound[0].featureEnabled
            },
            stamp: {
                asset: theme.iconStamp.length && theme.iconStamp[0].iconAsset[0].url,
                iconPosition: theme.iconStamp.length && theme.iconStamp[0].iconPosition,
                enabled: theme.iconStamp.length && theme.iconStamp[0].featureEnabled
            }
        }
        processedThemes.push(processedTheme)

    })
    return processedThemes
}

function loadFonts(fonts) {
    // console.log('fonts', fonts);

    var font_regular = new FontFace('FontRegular', `url(${fonts.regular})`);
    var font_bold = new FontFace('FontBold', `url(${fonts.bold})`);
    var font_extraBold = new FontFace('FontExtraBold', `url(${fonts.extraBold})`);
    font_extraBold.load().then(function (loaded_face) {
        document.fonts.add(loaded_face);
    }).catch(function (error) {
        console.log('error', error);
    });

    font_regular.load().then(function (loaded_face) {
        document.fonts.add(loaded_face);
        // console.log('loaded', loaded_face);
    }).catch(function (error) {
        console.log('error', error);
    });

    font_bold.load().then(function (loaded_face) {
        document.fonts.add(loaded_face);
    }).catch(function (error) {
        console.log('error', error);
    });
}

function getStyleSheet() {
    var style = document.createElement("style");    // WebKit hack
    style.appendChild(document.createTextNode(""));
    // Add the <style> element to the page
    document.head.appendChild(style);
    // console.log(style.sheet.cssRules); // length is 0, and no rules

    return style;
}

function setColours(theme) {
    // console.log('', theme);
    getStyleSheet().sheet.insertRule(
        `.primary {color: ${theme.colours.primary};}
        `, 0
    )
    getStyleSheet().sheet.insertRule(
        `.secondary {color: ${theme.colours.secondary};}
        `, 0
    )
    getStyleSheet().sheet.insertRule(
        `.highlight {color: ${theme.colours.highlight};}
        `, 0
    )
}

