import {siteStoreApi} from "../../store/siteStore";

export const query_audio  = () => `
  audio: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on audio_default_Entry {
      id
      audioLoop
      audioVolume
      audioAsset {
        id
        url
      }
    }
  }
`
