import * as THREE from "three"
import {extend} from "@react-three/fiber"

export class CircleMaterial extends THREE.ShaderMaterial {
    constructor() {
        super({
            extensions: {
                derivatives: "#extension GL_OES_standard_derivatives : enable"
            },
            side: THREE.DoubleSide,
            uniforms: {
                time: { type: "f", value: 2.99999},
                u_Color:  { type: "vec3", value: [ 1., 1., 0. ] },
                // t: { type: "t", value: null },
                opacity: { type: "f", value: 1 },
                u_InnerRadius: { type: "f", value: 0.3 },
                u_OuterRadius: { type: "f", value: 0.34 },
                resolution: { type: "v4", value: new THREE.Vector4() },
                uvRate1: {
                    value: new THREE.Vector2(1, 1)
                }
            },
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthWrite: false,
            depthTest: false,
            vertexShader: `
            precision mediump float;
            varying vec2 vUv;
          
            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.);
                gl_Position = projectionMatrix * mvPosition;
                vUv = uv;
            }
          `,
            fragmentShader: `
            varying vec2 vUv;
            uniform float time;
            uniform float opacity; 
            uniform float u_InnerRadius;
            uniform float u_OuterRadius;
            uniform vec4 resolution;
            uniform vec3 u_Color;
            float pi = 3.141592;
            
            
            const float PI = 3.14159265358979323846;
            const float TAU = 6.28318530717958647692;
            const float STEP_LENGTH = 0.01;
            const float ANGLE_OFFSET = PI*0.5; // angle of dial
            const vec4 color1 = vec4(1.0, 1.0, 1.0, 1.0);
            const vec4 color2 = vec4(1.0, 1.0, 1.0, 1.0);
            const float duration = 3.0; // duration of dial
            
            
            // Get the color value based on where in the circle the uv is
            vec4 getGradientValue(in vec2 uv)
            {
                vec2 dist = vec2(1.0, 0.0) - vec2(-1.0, 0.0);
                float val = dot( uv - vec2(-1,0), dist ) / dot( dist, dist );
                clamp( val, 0.0, 1.0 );
                    
                vec4 color = mix( color1, color2, val );
                // clamp depending on higher alpha value
                if( color1.a >= color2.a )
                    color.a = clamp( color.a, color2.a, color1.a );
                else
                    color.a = clamp( color.a, color1.a, color2.a );
                return color;
            }
            
            void main() {
            
            float progress = mod(time, duration) / duration;
            float innerRadius = u_InnerRadius;
            float outerRadius = u_OuterRadius;
            float startAngle = 0.0;
            float endAngle = progress* TAU;
            
            vec2 uv = -1.0 + (2.0 * vUv);
            vec2 newUV = (vUv - vec2(0.5))*resolution.xy/1000. + vec2(0.5);                
            //vec2 uv = -1.0 + (2.0 * newUV);               
            // vec2 uv = -1.0 + (2.0 * newUV);            
            // vec2 uv = vUv / 1.;
            
            float d = length( uv );
            vec4 ioColor = getGradientValue(uv);    

        
            // Perform adaptive anti-aliasing.
            float w = fwidth( d ) * 1.0;
            float c = smoothstep( outerRadius + w, outerRadius - w, d );
            c -= smoothstep( innerRadius + w, innerRadius - w, d );
            
            // set color for the area within inner and outer radius
            
            gl_FragColor = vec4(ioColor.rgb * vec3(c), opacity);
            
            vec4 col = vec4(u_Color, 1.);
            gl_FragColor = gl_FragColor *= col;
            
           
        
            // limit to active progress
            float angle = (atan(uv.y,uv.x)) + ANGLE_OFFSET;
            if( angle < 0.0 ) angle += PI * 2.0;
                if( angle > endAngle){
                    // float a = smoothstep( 0.75, -w*2.0,  abs(endAngle - angle) );        
                    float a = smoothstep( 0.0, -w*2.0,  abs(endAngle - angle) );        
                    gl_FragColor *= a;
                }
                if(angle - w*2.0 < startAngle ){
                    float a = smoothstep(  -w*2.0, w*2.0, (abs(startAngle - angle)) );
                    gl_FragColor *= a;    
                }
                
             float threshold = 0.9;
             if (gl_FragColor.r<threshold && gl_FragColor.b<threshold && gl_FragColor.g<threshold) discard;
            
            }
          `
        })
    }
}

extend({CircleMaterial})
