import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import {hotspotStoreApi} from "../hotspots/hotspotStore";

const [iconStore, iconStoreApi] = create((set, get) => ({
    allIcons: [],
    actions: {
        setAllIcons(icons) {
            set(state => ({ allIcons: processIcons(icons) }))
             // console.log('', get().allIcons);
        },
        getIconByID(id) {
            // console.log('', get().allIcons);
            return getValueByKey(get().allIcons, "id", id)
        },
        // called from Icon.js
        setVideoElement(hotspotID, vid, texture, channel) {
            let hotspot = hotspotStoreApi.getState().actions.getHotspotByID(hotspotID)
            hotspot.videoElement = vid;
            hotspot.videoTexture = texture;
            hotspot.channel = channel;
        },
        getVideoElement(hotspotID) {

        },
        hasVideoElement(hotspotID) {
            let hotspot = hotspotStoreApi.getState().actions.getHotspotByID(hotspotID)
            return hotspot.videoElement?true:false
        }
    }
}))

function processIcons(icons) {
    let processedIcons = []
    icons.forEach(icon => {
        let processedIcon = {}
        processedIcon.id = icon.id
        processedIcon.hotspotTransparency = false
        processedIcon.hotspotOpacity = icon.hotspotOpacity
        processedIcon.hotspotBillboard = icon.hotspotBillboard
        processedIcon.iconScale = icon.iconScale
        if (icon.hotspotVideo[0]) {
            // console.log('icon.hotspotVideo[0]', icon.hotspotVideo[0]);
            processedIcon.hotspotVideo = icon.hotspotVideo[0]?.id
            processedIcon.hotspotIconVideoTextureAudioEnabled = icon.hotspotIconVideoTextureAudioEnabled
        }
        processedIcon.url = icon.hotspotIcon[0].url
        processedIcons.push(processedIcon)
    })
    return processedIcons
}

export default iconStore
export {iconStoreApi}
