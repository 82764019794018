import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import {Howl, Howler} from 'howler';
import gsap from "gsap";
import {globalSetStoreAPI} from "../globalSets/globalSetStore";
import {videoStoreApi} from "../videos/videoStore";

const [audioStore, audioStoreApi] = create((set, get) => ({
    allAudios: [],
    currentAudio: null,
    currentAudioID: null,
    currentVolume: 1,
    savedVolume: 1,
    actions: {
        setAllAudios(audios) {
            set(state => ({ allAudios: processAudios(audios) }))
            // console.log('', get().allAudios);
        },
        stopCurrentAudio() {
            // console.log('stop current audio');
            if (get().currentAudio) {
                let currentHowl = get().actions.getAudioByID(get().currentAudioID)
                let volume = currentHowl.howl.volume()

                let o = {vol: volume}
                gsap.to(o, {
                    duration: 1, delay: 0, vol: 0, onUpdate: () => {
                        currentHowl.howl.volume(o.vol)
                    },
                    onComplete: ()=> {
                        currentHowl.howl.volume(volume)
                        currentHowl.howl.stop()
                    },
                    ease: "linear.none"
                })
            }
        },
        getAudioByID(id) {
            return getValueByKey(get().allAudios, "id", id)
        },
        toggleStateAudio(enable) {

            // fade out or stop current
            if (!get().currentAudio) return
            let currentHowl = get().actions.getAudioByID(get().currentAudioID)
            if (!enable) {
                if (get().currentAudio) {
                    currentHowl.howl.stop()
                }
            } else {
                if (!currentHowl.howl.playing()) {
                    currentHowl.howl.play()
                }
            }
        },
        playAudioByID(id, fade=false, duration=0) {
            // console.log('play audio', id);
            if (!id) return
            let audioSettings = globalSetStoreAPI.getState().globalSets.audioSettings

            /**
             * This function handles both the ambient and one-off audios
             * so check if it's looping and just play it, else do the usual fading etc
             */
            let one_off =  getValueByKey(get().allAudios, "id", id).howl
            if (one_off._loop === false) {
                one_off.stop()
                one_off.load()
                one_off.play()
                return;
            }

            // don't do anything if it's the same audio
            if (get().currentAudioID === id && get().currentAudio?._loop === true) {
                // console.log('same do nothing');
                return
            }

            // fade out or stop current
            if (get().currentAudio) {
                let currentHowl = get().actions.getAudioByID(get().currentAudioID)
                if (fade) {
                    let fadeDuration = duration * audioSettings.audioTransitionOut/100
                    let ease =  audioSettings.audioOutCurve
                    let o = {vol: currentHowl.baseVolume}
                    gsap.to(o, {
                        duration: fadeDuration, delay: 0, vol: 0, onUpdate: () => {
                            currentHowl.howl.volume(o.vol)
                        },
                        onComplete: ()=> {
                            currentHowl.howl.stop()
                        },
                        ease: getEase(ease)
                    })
                } else {
                    currentHowl.howl.stop()
                }
            }
            let audio =  getValueByKey(get().allAudios, "id", id)
            audio.howl.load()
            audio.howl.play()
            if (fade) {

                let fadeInDuration = duration * audioSettings.audioTransitionIn/100
                let ease =  audioSettings.audioInCurve

                let o = {vol: 0}
                audio.howl.volume(0)
                gsap.to(o, {
                    duration: fadeInDuration, delay: fadeInDuration, vol: audio.baseVolume, onUpdate: () => {
                        audio.howl.volume(o.vol)
                    },
                    onComplete: ()=> {
                        set(state => ({ currentAudio: audio.howl }))
                    },
                    ease: getEase(ease)
                })
            } else {
                // console.log('setting volume', audio.baseVolume);
                audio.howl.volume(audio.baseVolume)
                set(state => ({ currentAudio: audio.howl }))
            }

            set(state => ({ currentAudioID: id }))
        },
        setGlobalVolume(v) {
            set(state => ({ currentVolume: v }))
            Howler.volume(v)
            videoStoreApi.getState().videoElement.volume = get().currentVolume
        },
        setVisible(v) {
            // if the page is visible
            if (v) {
                Howler.volume(get().savedVolume)
                if (videoStoreApi.getState().videoElement) {
                    videoStoreApi.getState().videoElement.volume = get().savedVolume
                }
                set(state => ({ currentVolume: get().savedVolume }))
            // if the page is hidden
            } else {
                Howler.volume(0)
                if (videoStoreApi.getState().videoElement) {
                    videoStoreApi.getState().videoElement.volume = 0
                }
                set(state => ({ savedVolume: get().currentVolume }))
                set(state => ({ currentVolume: 0 }))
            }
        },
        stopAudio(action) {
            if (action.stoponeoffaudio) {
                get().allAudios.forEach( audio => {
                    if (audio.howl.playing() && audio.id !== get().currentAudioID) {
                        fadeSound(audio.howl)
                    }
                })
            }
            if (action.stopambientaudio) {
                let currentHowl = get().actions.getAudioByID(get().currentAudioID)
                fadeSound(currentHowl.howl)
            }
            if (action.stopvideoaudio) {
                videoStoreApi.getState().actions.playVideoAudio(false)
            }
        },
        toggleMute(v) {
            console.log('toggleMute', v);
            if (!v) {
                set(state => ({ savedVolume: get().currentVolume }))
                this.setGlobalVolume(0)
            } else {
                this.setGlobalVolume(1)
            }
        }
    }
}))

function fadeSound(howl) {
    let saveVolume = howl.volume()
    howl.once( 'fade', () => {
        howl.stop()
        howl.volume(saveVolume)
    } );
    howl.fade( howl.volume(), 0, 500);
}

function processAudios(audios) {
    let processedAudios = []
    audios.forEach(audio => {
        let processedAudio = {}
        processedAudio.id = audio.id
        processedAudio.url = audio.audioAsset[0].url
        processedAudio.loop = audio.audioLoop
        processedAudio.baseVolume = audio.audioVolume ? audio.audioVolume : 1
        processedAudio.howl =  new Howl({
            src: audio.audioAsset[0].url,
            preload: false,
            loop: audio.audioLoop,
            volume: audio.audioVolume ? audio.audioVolume : 1
        })
        processedAudios.push(processedAudio)
    })
    return processedAudios
}

function getEase(ease) {
    switch (ease) {
        case "easeIn":
            return "quad.easeIn"
        case "easeOut":
            return "quad.easeOut"
        case "linear":
            return "none"
    }

}


export default audioStore
export {audioStoreApi}
