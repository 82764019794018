import {memo, Suspense, useEffect, useRef} from "react"
import {
    DepthOfField,
    EffectComposer,
} from '@react-three/postprocessing'
import {isDesktop, isMobile, isTablet} from 'react-device-detect';
import create from "zustand";
import gsap from "gsap";
import modalStore from "../../../../store/modals/modalStore";
import {useAnimationFrame} from "../../../../utils/hooks";


const postStore = create((set, get) => ({
    blur: 0,
    showPost: false,
    setBlur: (v) => set({blur: v}),
    setShowPost: (v) => set({showPost: v}),
}))
function Postprocessing() {
    const showPost = postStore(state => state.showPost)
    const setShowPost = postStore(state => state.setShowPost)
    const openModal = modalStore(state => state.openModal)
    const closeModal = modalStore(state => state.closeModal)
    const blur = postStore(state => state.blur)
    const setBlur = postStore(state => state.setBlur)
    const blurRef = useRef()
    const o = useRef({v: 0})


    useEffect(() => {
        if (openModal) {
            setShowPost(true)
        }
        if (closeModal) {
            setShowPost(false)
        }
    }, [openModal, closeModal])


    useEffect(() => {

        gsap.killTweensOf(o.current)
        if (isDesktop) {
            gsap.to(o.current, {
                duration: showPost ? 1.5: 0, ease: "sine.out", v: showPost ? 3.3 : 0, onUpdate: () => {
                    setBlur(o.current.v)
                }
            })
        } else {
            if (isTablet) {
                setBlur(o.current.v)
            }
        }

    }, [showPost])



    return (
        <Suspense fallback={null}>
            <EffectComposer>
                {/*<DOF />*/}
                <DepthOfField focusDistance={20} focalLength={0.6} bokehScale={blur} height={400} ref={blurRef}/>
            </EffectComposer>
        </Suspense>
    )
}

export default memo(Postprocessing)
