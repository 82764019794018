import Interweave from "interweave";
import "@google/model-viewer/dist/model-viewer";
import {useEffect, useRef} from "react";
import gsap from "gsap"

function ModalModel({content}) {

    const modelRef = useRef()

    useEffect(()=> {
        if (!content) return
        if (!content.enableBackground) {
            document.body.classList.add("removebg")
        }

        modelRef.current.addEventListener("progress", (e)=> {
            if (e.detail.totalProgress === 1) {
                gsap.to(modelRef.current,  {duration: 0.5, alpha: 1, delay: 1.1})
            }
        })

        return ()=> {
            document.body.classList.remove("removebg")
        }
    }, [content])
    return (
        <>
                <model-viewer ref={modelRef} src={content.modal3D} alt="" autoplay camera-controls />
            {
                (content.modalSubCopy && !content.modalVideoFull) ?
                    <div className="modaldev__videocopy">
                        <Interweave content={content.modalSubCopy}/>
                    </div>
                    : null
            }
        </>
    )
}

export default ModalModel
