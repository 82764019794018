import {useEffect} from "react";

import create from "zustand";

const [errorStore, errorStoreApi] = create((set, get) => ({
    error: false,
    setError: (v) => set({error: v})
}))


function Error() {
     const error = errorStore(state => state.error)
     return (
         <div className="error" style={{display: error ? "flex" : "none"}}>
              <h2>Problem contacting server</h2>
         </div>
     )
}
export default Error
export {errorStoreApi}
