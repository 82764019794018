import {siteStoreApi} from "../../store/siteStore";

export const query_globalSets = () =>`
   globalSets: globalSets (site: "${siteStoreApi.getState().siteHandle}") {
   
   ... on siteManagement_GlobalSet {
      siteConfiguration {
        ... on siteConfiguration_site_BlockType {
          domain
          sitehandle
        }
      }
    }
   
    ... on styling_GlobalSet {
      chooseTheme {
        themeID: id
      }
    }
    ... on tracking_GlobalSet {
      trackingCode
    }
    ... on landingPage_GlobalSet {
      landingPageCopy: landingPageCopy
      landingPageTerms: landingPageTerms
      landingPageButton: landingPageButton
      landingPageBackground {
        url
      }
    }
    ... on menuSettings_GlobalSet {
      menuAbout: menuAbout
      menuMapTitle: menuMapTitle
      menuDisclaimer: menuDisclaimer
      menuShare: menuShare
      shareIcons: shareIcons
      menuToggles(label: true)
    }
    ... on audioSettings_GlobalSet {
      audioTransitionIn
      audioTransitionOut
      audioInCurve
      audioOutCurve
      sfxClick {
        id
      }
      sfxClose {
        id
      }
    }
    ... on metadata_GlobalSet {
      metaTitle
      metaDescription
      metaAuthor
      metaKeywords
      metaUrl
      ogLocale
      ogSiteName
      ogImage {
        url
      }
      favicon {
        favicon_16x16: url(transform:"favicon16x16")
        favicon_32x32: url(transform:"favicon32x32")
        favicon_36x36: url(transform:"favicon36x36")
        favicon_114x114: url(transform:"favicon114x114")
        favicon_120x120: url(transform:"favicon120x120")
        favicon_144x144: url(transform:"favicon144x144")
        favicon_152x152: url(transform:"favicon152x152")
        favicon_180x180: url(transform:"favicon180x180")
        favicon_192x192: url(transform:"favicon192x192")
        favicon_256x256: url(transform:"favicon256x256")
        favicon_384x384: url(transform:"favicon384x384")
        favicon_48x48: url(transform:"favicon48x48")
        favicon_57x57: url(transform:"favicon57x57")
        favicon_60x60: url(transform:"favicon60x60")
        favicon_72x72: url(transform:"favicon72x72")
        favicon_76x76: url(transform:"favicon76x76")
        favicon_96x96: url(transform:"favicon96x96")
      }
    }
    ... on experienceSettings_GlobalSet {
      skipButton {
        url
      }
      skipButtonTiming
      helpTiming
      initialScene {
        id
      }
      primaryCollectable {
        id
      }
    }
    ... on cookieConsent_GlobalSet {
      cookieConsentCopy
    }
    ... on edgecast_GlobalSet {
      userId
      apiKey
    }
  }
`
