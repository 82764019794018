import CookieConsent from "react-cookie-consent";
import {useEffect, useState} from "react";
import siteStore from "../store/siteStore";
import {getCookie} from "../utils/utilities";
import InnerHTML from "dangerously-set-html-content";
import globalSetStore from "../store/globalSets/globalSetStore";
import Interweave from "interweave";
import Tracking, {trackEvent, TrackingLabels} from "../tracking/Tracking";

function CookiesAndTracking() {

     const [showCookie, setShowCookie] = useState(true)
     const dropCookie = siteStore(state => state.dropCookie)
     const actions = siteStore(state => state.actions)
     const globalSets = globalSetStore(state => state.globalSets)

     function onAcceptCookie() {
          actions.setDropCookie(true)
          setShowCookie(false)

          setTimeout(() => {
               // tracking
               trackEvent(
                   {
                        name: TrackingLabels.names.BUTTON_CLICK,
                        category: TrackingLabels.categories.LANDING_PAGE_COOKIES,
                        label: 'accept'
                   }
               )
               // end tracking
          }, 3000)
     }

     function onDeclineCookie() {
          actions.setDropCookie(false)
          setShowCookie(false)
     }

     useEffect(() => {
          if (!globalSets) return
          let c = getCookie("CookieConsent");
          if (c === "true") {
               // console.log('dropping cookie', );
               actions.setDropCookie(true)
               setShowCookie(false)
          }
          if (c === "false") {
               // console.log('dont dropping cookie', );
               actions.setDropCookie(false)
               setShowCookie(false)
          }
     }, [globalSets])

     return (
         <>

              <Tracking/>

              {
                   dropCookie ?
                       <InnerHTML html={getTrackingCode(globalSets.trackingCode)}/>
                       : null
              }
              <div className="ccwrapper" style={{display: showCookie ? "flex" : "none"}}>
                   {
                        globalSets ?
                            <CookieConsent buttonText="Accept" declineButtonText="DECLINE" onAccept={onAcceptCookie}
                                           onDecline={onDeclineCookie} enableDeclineButton flipButtons>
                                 <Interweave content={globalSets.cookieConsent}/>
                            </CookieConsent>
                            : null
                   }
              </div>

         </>
     )
}

export default CookiesAndTracking

function getTrackingCode(code) {
    console.log('code', code);
     return `
    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=${code}"></script>
    <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    
    gtag('config', '${code}');
    </script>
`
}

