// all videos including 360s and 2ds
// handling HLS etc
import create from "zustand";
import {getValueByKey} from "../../utils/utilities";
import {createHLSObject} from "./hlsmanager/HLSManager";
import * as THREE from 'three';
import {sceneStoreApi} from "../scenes/sceneStore";
import {routeStoreAPI} from "../routes/routeStore";
import {boomerangStoreAPI} from "../boomerangs/boomerangStore";
import {globalSetStoreAPI} from "../globalSets/globalSetStore";
import {isDesktop, isIOS, isMobile} from "react-device-detect";

const VIDEO_TYPE_BOOMERANG = "VIDEO_TYPE_BOOMERANG"
const VIDEO_TYPE_ROUTE = "VIDEO_TYPE_ROUTE"
const VIDEO_TYPE_360 = "VIDEO_TYPE_360"

const [videoStore, videoStoreApi] = create((set, get) => ({
    allVideoData: null,
    activeVideos: [], // this is populated by seeing which videos are used in the site
    allHLSStreams: null,
    videoElement: null,
    videoTexture: null,
    currentHLS: null,
    play2D: false,
    videoEnded: null,
    videoType: false,
    videoPlaying: false,
    videoRestart: null,
    videoDuration: 0,
    showSkip: false,
    canSkip: false,
    fragLoaded: 0,
    actions: {
        /**
         * All video data from GraphQL
         * @param videos
         */
        setAllVideos(videos) {
            const allVideoData = processVideos(videos);

            set(state => ({allVideoData: allVideoData}))

            const hlsStreams = createHLSStreams(allVideoData)
            set(state => ({allHLSStreams: hlsStreams}))

            get().actions.createVideo(false)

            checkVideoTime()
        },
        addActiveVideo(v) {
            set(state => ({activeVideos: [...get().activeVideos, v]}))

            // console.log('', get().activeVideos);
        },
        setActiveVideos(videos) {

        },
        /**
         * @param id
         * @returns json from graphQL
         */
        getVideoByID(id) {
            return getValueByKey(get().allVideoData, "id", id)
        },
        /**
         * Returns object containing hls object
         * @param id
         */
        getHLSObjectByVideoId(id) {
            return get().allHLSStreams.find(x => x.id === id)
        },
        /**
         * This is the video that gets used for three.js textures
         * @param attachToDom for debugging
         */
        createVideo(attachToDom) {
            const vid = document.createElement("video");
            vid.classList.add("previewVideo")
            vid.crossOrigin = "Anonymous";
            vid.playsInline = true;
            vid.muted = true;
            vid.loop = false;
            let texture = new THREE.VideoTexture(vid);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.format = THREE.RGBFormat;
            set(state => ({videoElement: vid}))
            set(state => ({videoTexture: texture}))
            if (attachToDom) {
                document.body.appendChild(vid)
            }
            vid.addEventListener("ended", ()=> {
                onEnd(get, set)
            })
            return vid
        },
        getEdgeCastStream(audioEnabled) {
            const vid = document.getElementById("myVideoId");
            vid.crossOrigin = "Anonymous";
            vid.playsInline = true;
            vid.muted = !audioEnabled;
            let texture = new THREE.VideoTexture(vid);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.format = THREE.RGBFormat;
            playPromise(vid)
            return {texture: texture, videoElement: vid}
        },
        getHotspotVideoTexture(src, audioEnabled) {
            const vid = document.createElement("video");
            vid.crossOrigin = "Anonymous";
            vid.playsInline = true;
            vid.muted = !audioEnabled;
            vid.loop = true;
            vid.src = src;
            let texture = new THREE.VideoTexture(vid);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.format = THREE.RGBFormat;
            playPromise(vid)
            return {texture: texture, videoElement: vid}
        },
        playVideo(id, ...options) {
            // console.log('playing video');

            if (!isMP4()) {
                this.playHLS(id)
            } else {
                this.playMP4(id)
            }
        },
        playHLS(id) {
            const hlsObject = get().actions.getHLSObjectByVideoId(id);
            const videoElement = get().videoElement;
            /**
             * Detach current HLS object
             */
            if (get().currentHLS) {
                // console.log('111', );
                videoElement.pause()
                videoElement.currentTime = 0
                get().actions.setVideoPlaying(false)
                get().currentHLS.detachMedia(videoElement)
                hlsObject.hls.attachMedia(videoElement)

                setTimeout(()=> {
                    playPromise(videoElement)
                    syncFader()
                }, 100)


            } else {
                // console.log('play HLS', );
                hlsObject.hls.attachMedia(videoElement)
                playPromise(videoElement)
            }

            /**
             * Set current HLS to selected video
             */
            set(state => ({currentHLS: hlsObject.hls}))
        },
        playMP4(id) {
            const videoElement = get().videoElement;
            const mp4 = get().actions.getVideoByID(id).highBitrateMp4
            videoElement.pause()
            videoElement.currentTime = 0
            videoElement.src = mp4
            playPromise(videoElement)
        },
        play360(id, sync) {
            // console.log('sync', sync);
            set(state => ({videoType: VIDEO_TYPE_360}))
            this.playVideo(id)
            if (sync) syncFader()
        },
        setPlay2D(v) {
            set(state => ({play2D: v}))
        },
        playRoute(route) {
            // console.log('playRoute', route.routeVideo);
            set(state => ({videoType: VIDEO_TYPE_ROUTE}))
            set(state => ({play2D: true}))
            get().videoElement.loop = false;

            if (route.boomerangSkippable) {
                this.setCanSkip(true)
            } else {
                this.setCanSkip(false)
            }
            this.playVideo(route.routeVideo)
            get().videoElement.currentTime = 0

            syncFader()
        },
        playBoomerang(boomerang) {

            set(state => ({videoType: VIDEO_TYPE_BOOMERANG}))
            set(state => ({play2D: true}))
            get().videoElement.loop = false;

            if (boomerang.boomerangSkippable) {
                this.setCanSkip(true)
            } else {
                this.setCanSkip(false)
            }

            this.playVideo(boomerang.boomerangVideo)

            get().videoElement.pause()
            get().videoElement.currentTime = 0
            setTimeout(()=> {
                // get().videoElement.play()
                syncFader()
            }, 0)

        },
        skipVideo() {
            // console.log('skipping', get().videoType);
            onSkip(get)
            onEnd(get, set)
        },
        setVideoPlaying(v) {
            set(state => ({videoPlaying: v}))
        },
        getVideoPlaying(v) {
            return get().videoElement
        },
        setVideoRestart() {
            sceneStoreApi.getState().actions.doVideoRestart()
            // set(state => ({videoRestart: Date.now()}))
        },
        setVideoDuration(v) {
            set(state => ({videoDuration: v}))
        },
        getVideoDuration(v) {
            return get().videoDuration
        },
        setShowSkip(v) {
            set(state => ({showSkip: v}))
        },
        playVideoAudio(v) {
            if (v) {
                get().videoElement.muted = false
                // console.log('video audio playing');
            } else {
                get().videoElement.muted = true
                // console.log('video audio muted');
            }
        },
        setCanSkip(v) {
            set(state => ({canSkip: v}))
        },
        preloadVideos() {
            if (!isMP4()) {
                preloadActiveVideos(get)
            }
        },
        setFragLoaded() {
            set(state => ({fragLoaded: get().fragLoaded+1}))

            // console.log('', get().fragLoaded);
        }
    }
}))

export function playPromise(videoElement) {
    const playPromise = videoElement.play();
    if (playPromise !== undefined) {
        playPromise.then(_ => {
        })
            .catch(error => {
            });
    }
}
/**
 * Put all 2D and 360 videos into one
 * @param v
 * @returns {*[]}
 */
function processVideos(v) {
    let all = []
    // console.log('p', v);
    v.twod.forEach(video => all.push(video))
    v.threed.forEach(video => all.push(video))
    return all;
}

/**
 * TODO: add all other mp4s and streams
 * @param allVideoData
 */
function createHLSStreams(allVideoData) {
    let processedVideos = []
    // console.log('isIOS', isIOS);
    allVideoData.forEach(video => {
        let videoObj = {}
        videoObj.id = video.id

        if (!isMP4()) {
            videoObj.hls = createHLSObject()
            // console.log('', video.lowBitrateHlsStream);
            if (video.lowBitrateHlsStream) {
                videoObj.source = video.lowBitrateHlsStream || null
            } else {
                videoObj.source = video.highBitrateHlsStream || null
            }

            if (video.highBitrateHlsStream) {
                if (video.highBitrateHlsStream.toString().includes("youtube")) {
                    videoObj.hls = null
                } else {
                    // videoObj.hls.loadSource(video.highBitrateHlsStream)
                }
            }
        }
        processedVideos.push(videoObj);
    })
    return processedVideos
}

function preloadActiveVideos(get) {
    // console.log('allHLSStreams', get().allHLSStreams);

    // console.log('get().activeVideos', get().activeVideos.length);

    get().activeVideos.forEach(id => {
        let video = get().allHLSStreams.find(x => x.id === id)
        // console.log('', video);
        video.hls.loadSource(video.source)
    })
}

function checkVideoTime() {
    let currentVideo = videoStoreApi.getState().videoElement
    if  (currentVideo.duration > 0 && currentVideo.currentTime === 0) {
        // console.log('', currentVideo.currentTime, currentVideo.duration)
    }
    if (globalSetStoreAPI.getState().globalSets) {
        if (currentVideo.currentTime > globalSetStoreAPI.getState().globalSets.experience.skipButtonTiming) {
            if (videoStoreApi.getState().canSkip) {
                videoStoreApi.getState().actions.setShowSkip(true)
            }
        } else {
            videoStoreApi.getState().actions.setShowSkip(false)
        }
    }
    requestAnimationFrame(checkVideoTime)
}

function onEnd(get, set) {
    // get().videoElement.pause()
    switch (get().videoType) {
        case VIDEO_TYPE_ROUTE:
            // console.log('end', VIDEO_TYPE_ROUTE);
            get().videoElement.pause()
            routeStoreAPI.getState().actions.onEnd()
            set(state => ({play2D: false}))
            break
        case VIDEO_TYPE_BOOMERANG:
            // console.log('end', VIDEO_TYPE_BOOMERANG);
            get().videoElement.pause()
            boomerangStoreAPI.getState().actions.onEnd()
            set(state => ({play2D: false}))
            break
        default:
            // console.log('on end');
            get().videoElement.play()
            get().actions.setVideoRestart()
    }

    // get().videoElement.loop = true
}

function onSkip(get) {
    switch (get().videoType) {
        case VIDEO_TYPE_BOOMERANG:
            boomerangStoreAPI.getState().actions.doSkip()
            break
        default:
    }
}

export default videoStore;
export {videoStoreApi}

function syncFader() {
    let vid = videoStoreApi.getState().videoElement
    if (vid && vid.currentTime > 0.25) {
        // console.log('vv', vid.currentTime);
        videoStoreApi.getState().actions.setVideoPlaying(true)
        videoStoreApi.getState().actions.setVideoDuration(vid.duration)
    } else {
        // console.log('waiting', );
        videoStoreApi.getState().actions.setVideoPlaying(false)
        requestAnimationFrame(syncFader)
    }
}

function isMP4() {
    return isMobile
}
