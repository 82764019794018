import {siteStoreApi} from "../../store/siteStore";

export const query_livestreams = (id) => `
  livestreams: entries(site: "${siteStoreApi.getState().siteHandle}") {
     ... on livestreamVideo_default_Entry {
      id
      title
      edgecastEventId
      activateLiveStream
      livestreamPending
    }
  }
`
