import {useEffect} from "react";
// import IconHotspot from "../../../three/hotspots/hotspotComponents/IconHotspot";
import {actionsStoreApi} from "../../../../../store/actions/actionStore";
import {doActions} from "../../../../../store/actionBuilderProcessing";
import themeStore from "../../../../../store/themes/themeStore";
import {trackEvent, TrackingLabels} from "../../../../../tracking/Tracking";


function ModalActions({buttons, direction, modal}) {
    const theme = themeStore(state => state.theme)


    useEffect(()=> {
        if (!buttons) return;
        // console.log('', buttons);
    }, [buttons])

    function onClick(index) {
        // TODO: sort this
        let actions = buttons[index].actions
        console.log('', buttons[index]);

        switch (buttons[index].button) {
            case "primary":

                break
            case "secondary":

                break
            default:
        }

        doActions(actions);


        // tracking
        if (modal) {
            const modal_name = modal.content.title
            const button_label = buttons[index].button
            trackEvent(
                {
                    name: buttons[index].buttonType === "primary" ? TrackingLabels.names.PRIMARY_CLICK : TrackingLabels.names.SECONDARY_CLICK,
                    category: TrackingLabels.categories.MODAL,
                    label: `modal_${modal_name}_${button_label}`
                }
            )
            // end tracking
        }
    }

    // `${direction==="vertical" ? 'column' : 'row'}`
    return (
        <div className={`uibutton__container ${direction==="vertical" ? 'uibutton__container--column' : 'uibutton__container--row'}`} >
            {
                buttons && buttons.map((button, index) => (
                    <div className={`uibutton ${button.buttonType === "primary" ? "uibutton__primary" : "uibutton__secondary"}`}
                         style={button.buttonType === "primary" ? theme.buttonStyle : theme.buttonStyleSecondary}
                         onClick={()=> onClick(index)}
                         key={index}>{button.button}</div>
                ))
            }
        </div>
    )
}
export default ModalActions
