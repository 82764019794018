import Interweave from "interweave";
import {useEffect, useRef} from "react";
import {videoStoreApi} from "../../../../../store/videos/videoStore";
import ReactPlayer from 'react-player';

import create from "zustand";
import modalStore from "../../../../../store/modals/modalStore";
import audioStore from "../../../../../store/audio/audioStore";
import {modalTypeStore} from "../Modal";
import {doActions} from "../../../../../store/actionBuilderProcessing";

const [modalVideoStore] = create((set) => ({
    videoID: 0,
    playing: false,
    startTime: 0,
    setVideoID: (v) => set({videoID: v}),
    setPlaying: (v) => set({playing: v}),
    setStartTime: (v) => set({startTime: v})
}))

function ModalVideo({content}) {

    console.log('', content);

    const currentVolume = audioStore(state => state.currentVolume)
    useEffect(()=> {
        let video = videoStoreApi.getState().actions.getVideoByID(content.videoID)
        if (!video) return
        let vid;
        if (video.highBitrateMp4) {
            vid = video.highBitrateMp4
        } else {
            vid = video.lowBitrateMp4
        }

        /**
         * Check if prefer HLS
         */
        if (content.preferHls) {
            vid = video.highBitrateHlsStream
        }

        /**
         * Check if start time
         */
        if (video.startTime) {
            setStartTime(video.startTime)
        }
        setVideoID(vid)
    }, [])


    useEffect(()=> {
        // console.log('currentVolume', currentVolume);
    }, [currentVolume])

    const playerRef = useRef()
    const id = modalVideoStore(state => state.videoID)
    const setVideoID = modalVideoStore(state => state.setVideoID)
    const playing = modalVideoStore(state => state.playing)
    const setPlaying = modalVideoStore(state => state.setPlaying)
    const startTime = modalVideoStore(state => state.startTime)
    const setStartTime = modalVideoStore(state => state.setStartTime)
    const closeModal = modalStore(state => state.closeModal)
    const openModal = modalStore(state => state.openModal)
    const setVideoEnded = modalTypeStore(state => state.setVideoEnded)

    useEffect(()=> {
        if (!closeModal) return
        console.log('closing', closeModal);
        setPlaying(false)
        setStartTime(0)
        document.body.classList.remove("portraitvid")

    }, [closeModal])

    useEffect(()=> {
        if (!closeModal) {
            document.querySelector(".videoWrapper").style.opacity = 0;
            setPlaying(true)
        }
    }, [openModal])

    function onEnded() {

        if (content.videoEvents.length && content.videoEvents[0].modalVideoEvents === "onVideoComplete") {
            // console.log('', content.videoEvents[0].actions);
            doActions(content.videoEvents[0].actions);
        }
        // setVideoEnded()
    }

    function onReady() {
        let video= document.querySelector(".videoWrapper").querySelector("video")
        console.log('on ready', video.getBoundingClientRect())
        // console.log('', video.videoWidth, video.videoHeight);
        setTimeout(()=> {
            if (video.getBoundingClientRect().width > video.getBoundingClientRect().height) {
                video.style.width = "100%"
                video.style.height = "auto"

                document.body.classList.remove("portraitvid")
            } else {
                document.body.classList.add("portraitvid")

            }
            document.querySelector(".videoWrapper").style.opacity = 1;
        }, 300)
        if (startTime) {
            console.log('', );
            video.currentTime = startTime;
        }

    }

    return (
        <div className={`${content.modalVideoFull ? "blah" : ""}`}>
            <ReactPlayer ref={playerRef} class="videoWrapper"
                         volume={parseFloat(currentVolume)} controls={true}
                         loop={false} muted={false} playing={playing} url={`${id}`}  playsinline onEnded={onEnded}
                         onReady={onReady}
            />
            {
                (content.modalSubCopy && !content.modalVideoFull) ?
                    <div className="modaldev__videocopy">
                        <Interweave content={content.modalSubCopy}/>
                    </div>
                    : null
            }
        </div>
    )
}

export default ModalVideo
