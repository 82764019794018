import Interweave from "interweave";

function ModalContent(content) {
    return (
        <div>
            <Interweave content={content.content}/>
        </div>
    )
}

export default ModalContent
