export const query_actions = `
        actions {
            ... on actions_cancelmodal_BlockType {
              typeHandle
            }
            ... on actions_setvolume_BlockType {
              typeHandle
              setVolume: param
            } 
            ... on actions_togglevideotextureaudio_BlockType {
              typeHandle
              chooseHotspot {
               id
              }
              videoTextureAudioOnOff
            }
            ... on actions_playroute_BlockType {
              typeHandle
              param {
                id
              }
            }
            ... on actions_openmodal_BlockType {
              typeHandle
              modalID: param {
                id
              }
            }
            ... on actions_setstate_BlockType {
              typeHandle
              scene {
                id
              }
              stateHandle: param
            }
             ... on actions_setscene_BlockType {
              typeHandle
              scene {
                id
              }
            }
            ... on actions_playboomerang_BlockType {
              typeHandle
              boomerangID: param {
                id
              }
            }
            ... on actions_openexternal_BlockType {
              typeHandle
              url: param
            }
            ... on actions_setmapactive_BlockType {
              typeHandle
              scene {
                id
              }
              active
            }
            ... on actions_openstatehelp_BlockType {
              typeHandle
            }
            ... on actions_playvideoaudio_BlockType {
              typeHandle
              playing
            }
            ... on actions_playaudio_BlockType {
              typeHandle
              audioID: param {
                id
              }
            }
             ... on actions_togglestateaudio_BlockType {
              typeHandle
              enabled: param
            }
             ... on actions_setfullscreenvideohotspot_BlockType {
              typeHandle
              enabled: param
            }
             ... on actions_stopaudio_BlockType {
              typeHandle      
              stoponeoffaudio       
              stopambientaudio
              stopvideoaudio
            }
            ... on actions_collectachievement_BlockType {
              typeHandle
              collection: param {
                id
              }
            }
          }
`
