import React from "react";

export function Lights() {
    return (
        <>
        <ambientLight intensity={1} />
        <directionalLight
            position={[110, 225, -280]}
            intensity={6}
            castShadow
            shadow-mapSize-height={1024}
            shadow-mapSize-width={1024}
            shadow-radius={10}
            shadow-bias={-0.0001}
        />
        </>
    )
}
