/**
 * for preloading videos with progress
 * @param url
 * @param dispatch, link to context
 */
export const LoadVideo = async (url, display) => {
    // console.log('loadvideo', `${url}`);
    const response = await fetch(`${url}`);
    const length = response.headers.get('Content-Length');
    if (!length) {
        console.log('error loading video - no bytes');
        return await response.arrayBuffer();
    }
    const array = new Uint8Array(length);
    let at = 0;
    const reader = response.body.getReader();
    let downloadIsDone = false
    while (!downloadIsDone) {
        const {done, value} = await reader.read();
        if (done) {
            downloadIsDone = done
            break;
        }
        array.set(value, at);
        at += value.length;
        let progress = (at / length).toFixed(2);
        if (display) {
            display(progress)
        }
    }
    let blob = new Blob([array], {type: 'video/mp4'});
    let videoURL = URL.createObjectURL(blob);
    // let videoURL2 = createObjectURL(blob);
    // console.log('', videoURL);
    // console.log('', videoURL2);


    /*fetch(`${url}`)
        .then(response => response.blob())
        .then(imageBlob => {
            let objectURL = URL.createObjectURL(imageBlob);
            console.log('', objectURL);
        });
    */
    return videoURL;
}

export function createObjectURL(object) {
    return (window.URL) ? window.URL.createObjectURL(object) : window.webkitURL.createObjectURL(object);
}

export function getContentPath(asset) {
    if (!asset) return;
    let path;
    if(window.location.href.indexOf('localhost') !== -1){
        path = process.env.REACT_APP_API_LOCAL;
    } else {
        path = process.env.REACT_APP_API_PROD_DATA;
    }
    return `${path}content/${asset}`;
}

export function removeGraphQLBlankObjects(arr) {
    return arr.filter(value => Object.keys(value).length !== 0);
}
