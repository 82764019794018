import React, {useCallback, useRef} from "react";
import * as THREE from "three";
import create from 'zustand'
import {Billboard} from "@react-three/drei";
import "./_hotspotcreator.scss"
import {useKeyPress} from "../../../../utils/hooks";

const hotspotStore = create(set => ({
    hotspots: [],
    addHotspots: (newHotspots) => set(state => ({ hotspots: newHotspots }))
}))

function Sphere() {
    const addHotspots = hotspotStore(state => state.addHotspots);
    const hotspots = hotspotStore(state => state.hotspots);
    const geom = useRef();

    const onAdd = (point)=> {
        console.log('', `${point.x}, ${point.y}, ${point.z}`);
        // actions.setHotspot(point.x,point.y,point.z)

        let arr = [...hotspots];
        arr.push({ position: [point.x, point.y, point.z], color: 0x00ff00, move: true, label: `${arr.length }` });
        addHotspots(arr)
    }

    useKeyPress("w", ()=> {
        if (window.isDebug) {
            geom.current.scale.x = geom.current.scale.y = geom.current.scale.z += 0.025;
            console.log('scale', geom.current.scale.x);
        }
    }, [geom])
    useKeyPress("s", ()=> {
        if (window.isDebug) {
            geom.current.scale.x = geom.current.scale.y = geom.current.scale.z -= 0.025;
            console.log('scale', geom.current.scale.x);
        }
    }, [geom])


    return (
        <mesh ref={geom} scale={[1, 1, 1]} onDoubleClick={(e)=> {
            // console.log('', e);
            onAdd(e.point)
        }}>

            <sphereBufferGeometry args={[400, 32, 32]} attach="geometry" />
            <meshBasicMaterial transparent opacity={0.1} attach="material" side={THREE.DoubleSide} wireframe/>
        </mesh>
    )
}

const HotspotCreator = ({existingHotspots=[], callback=null, isEnd})=> {
    const box = useRef();
    let existing = [];
    for (let i=0; i<existingHotspots.length; i++) {
        let hs = { position: [
                existingHotspots[i].position.x,
                existingHotspots[i].position.y,
                existingHotspots[i].position.z],
                color: 0x00ff00,
                move: true,
                label: i,
                title:  existingHotspots[i].title,
                copy:  existingHotspots[i].copy,
                buttons:  getButtons(existingHotspots[i].buttons),
                media:  existingHotspots[i].media,
                visibility:  existingHotspots[i].visibility,
            }
        existing.push(hs)
    }

    function getButtons(buttons) {
        // console.log('getting buttons', buttons);
        if (buttons && buttons.length) {
            return [...buttons]
        } else {
            return null
        }
    }


    const HotspotLabel = (props)=> {
        // console.log('', props);
        function onClick(idx) {
            // callback(idx);
        }
        return (
            <group {...props}>
            <Billboard args={[30, 30, 30]}
                       follow={true} // Follow the camera (default=true)
                       lockX={false} // Lock the rotation on the x axis (default=false)
                       lockY={false} // Lock the rotation on the y axis (default=false)
                       lockZ={false} // Lock the rotation on the z axis (default=false)
            >
                {/*<Box position={[0,0,0]} ref={box}/>*/}
                <meshBasicMaterial attach="material" opacity="1" color={"red"} transparent="true"/>
                {/**/}{/*<Html>
                    <div onClick={()=> { onClick(props.label) }} className={`${props.label} visible--${props.visibility} hotspot`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '80px', height: '80px', borderRadius: '50%', padding: '5px', color: '#333333', transform: 'translate3d(-50%,-50%,0)', textAlign: 'center', userSelect: 'none', fontSize:'30px' }}>
                        <div className="hotspot__label">
                        {
                            props.position[0].toFixed(0)
                        }
                        <br/>
                        {
                            props.position[1].toFixed(0)
                        }
                            <br/>
                        {
                            props.position[2].toFixed(0)
                        }
                        </div>
                    </div>
                </Html>*/}
            </Billboard>
            </group>
        )
    }

    const AllBoxes = ()=> {
        const hotspots = hotspotStore(state => state.hotspots)
        return (
            hotspots.map((props, index) => (
                <HotspotLabel key={index} {...props} />
            ))
        )
    }


    return (
        <>
            <Sphere />
             <AllBoxes />
        </>
    )
}
export default HotspotCreator
