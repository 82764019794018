/**
 * An ActionBuilder object is an array of groups of actions
 * Each group of actions is an object called "actions" and contains an array
 */
import {actionsStoreApi} from "./actions/actionStore";

export function doActions(actions) {
    actions.forEach(action => {
        // console.log('', action);
    })
    actionsStoreApi.getState().actions.processActionArray(actions)
}
