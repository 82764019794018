import {siteStoreApi} from "../../store/siteStore";

export const query_themes = () => `
  themes: entries(section: "themes", site: "${siteStoreApi.getState().siteHandle}") {
    ... on themes_default_Entry {
      iconFullscreen {
        ... on iconFullscreen_icon_BlockType {
          featureEnabled  
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconGyro {
        ... on iconGyro_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconSound {
        ... on iconSound_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconMenu {
        ... on iconMenu_iconi_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconSound {
        ... on iconSound_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      iconStamp {
        ... on iconStamp_icon_BlockType {
          featureEnabled
          iconPosition
          iconAsset {
            url
          }
        }
      }
      logo {
        url
      }
      id
      title
      borderRadius
      buttonRadius
      buttonBorder
      capitaliseModalTitles
      capitaliseButtons
      modalTitleColour
      modalTitleColourDark
      modalTitleFontSize
      iconsOpacity
      bodyFont {
        id
        url
      }
      bodyTextSize
      boldFont {
        id
        url
      }
      extraBoldFont {
        id
        url
      }
      colourPrimary {
        ... on colourPrimary_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      colourSecondary {
        ... on colourSecondary_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      colourHighlight {
        ... on colourHighlight_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
      landingPageColour {
        ... on landingPageColour_colour_BlockType {
          colourValue
          colourOpacity
        }
      }
    }
  }
`
