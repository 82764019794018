import {siteStoreApi} from "../../store/siteStore";

export const query_livestreams_specific = (id) => `
  livestreams: entries(site: "${siteStoreApi.getState().siteHandle}", id: ${id}) {
     ... on livestreamVideo_default_Entry {
      id
      title
      edgecastEventId
      activateLiveStream
      livestreamPending
    }
  }
`
