import {siteStoreApi} from "../../store/siteStore";

export const query_icons = () =>`
  icons: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on icons_default_Entry {
      id
      hotspotOpacity
      hotspotBillboard
      iconScale
      hotspotVideo {
        id
      }
      hotspotIconVideoTextureAudioEnabled
      hotspotIcon {
        id
        url
      }
    }
  }
`
