
import pako from 'pako';
import hmacSHA256 from 'crypto-js/hmac-sha256';

import moment from 'moment';

function AuthParams(userID, apiKey) {
  const tmp = {
    _owner: userID,
    _timestamp: moment().unix(),
  };
  const json_str = JSON.stringify(tmp);
  const comp = pako.deflate(json_str, { level: 9 });
  const compStr = String.fromCharCode(...comp);
  var msg = btoa(compStr);
  const sig = hmacSHA256(msg, apiKey).toString();
  return { msg, sig };
}

export default AuthParams;
