/**
 * This is the home page
 * @returns {JSX.Element}
 * @constructor
 */
import Experience from "../experience/Experience";

function PageExperience() {

    return (
        <div className="page">
            <Experience />
        </div>
    )
}

export default PageExperience
