// loading data and passing assets to other stores
// initialise things
import create from "zustand"
import {loadSpecificQuery, loadQueries} from "../utils/getAppData"
import {videoStoreApi} from "./videos/videoStore";
import {sceneStoreApi} from "./scenes/sceneStore";
import {modalStoreApi} from "./modals/modalStore";
import {clean, removeGraphQLBlankObjects, trimEmptyObjects} from "../utils/loaders";
import {hotspotStoreApi} from "./hotspots/hotspotStore";
import {iconStoreApi} from "./icons/iconsStore";
import {audioStoreApi} from "./audio/audioStore";
import {routeStoreAPI} from "./routes/routeStore";
import {boomerangStoreAPI} from "./boomerangs/boomerangStore";
import {galleriesStoreApi} from "./galleries/galleriesStore";
import {themeStoreAPI} from "./themes/themeStore";
import {globalSetStoreAPI} from "./globalSets/globalSetStore";
import {helpStoreApi} from "./help/helpStore";
import {query_collectables} from "../graphql/queries/collectables";
import {useCollectableStoreApi} from "./collectables/collectableStore";
import {useLiveStreamStoreApi} from "./livestreams/livestreamStore";
import {buildQuery} from "../graphql/queries";
import {query_livestreams} from "../graphql/queries/livestreams";
import {errorStoreApi} from "../sitecomponents/Error";


const [siteStore, siteStoreApi] = create((set, get) => ({
    siteData: null,
    dropCookie: false,
    siteError: false,
    siteHandle: "default",
    actions: {
        setSiteData: (v) => set({siteData: v}),
        setHandle: (v) => {
            set({siteHandle: v})
            // console.log('', get().siteHandle);
            get().actions.getSiteData()
        },
        getSpecificData: async (query)=> {
            return await loadSpecificQuery(query)
        },
        getSiteData: async () => {
            const response = await loadQueries()
            if (!response) {
                errorStoreApi.getState().setError(true)
                return
            }
            if (response.errors && response.errors.length) {
                console.log(response.errors);
                set(state => ({ siteError: true }))
                return
            }
            /**
             * Process Scenes
             */
            response.data.scenes = removeGraphQLBlankObjects(response.data.scenes)
            response.data.hotspots = removeGraphQLBlankObjects(response.data.hotspots)
            response.data.icons = removeGraphQLBlankObjects(response.data.icons)
            response.data.audio = removeGraphQLBlankObjects(response.data.audio)
            response.data.routes = removeGraphQLBlankObjects(response.data.routes)
            response.data.boomerangs = removeGraphQLBlankObjects(response.data.boomerangs)
            response.data.galleries = removeGraphQLBlankObjects(response.data.galleries)
            response.data.themes = removeGraphQLBlankObjects(response.data.themes)
            response.data.globalSets = removeGraphQLBlankObjects(response.data.globalSets)
            response.data.helps = removeGraphQLBlankObjects(response.data.helps)
            response.data.collectables = removeGraphQLBlankObjects(response.data.collectables)
            response.data.livestreams = removeGraphQLBlankObjects(response.data.livestreams)

            /**
             * Process scenes
             */
            sceneStoreApi.getState().actions.setAllScenes(response.data.scenes)

            /**
             * Process icons
             */
            iconStoreApi.getState().actions.setAllIcons(response.data.icons)
            /**
             * Process hotspots
             */
            hotspotStoreApi.getState().actions.setAllHotspots(response.data.hotspots)

            /**
             * Process videos
             */
            const videos = {
                twod: response.data.twod,
                threed: response.data.threed
            }
            videoStoreApi.getState().actions.setAllVideos(videos)

            /**
             * Process modals
             */
            modalStoreApi.getState().actions.setAllModals(response.data.modals)

            /**
             * Process audio
             */
            audioStoreApi.getState().actions.setAllAudios(response.data.audio)

            /**
             * Process routes
             */
            routeStoreAPI.getState().actions.setAllRoutes(response.data.routes)

            /**
             * Process routes
             */
            boomerangStoreAPI.getState().actions.setAllBoomerangs(response.data.boomerangs)

            /**
             * Process galleries
             */
            galleriesStoreApi.getState().actions.setAllGalleries(response.data.galleries)

            /**
             * Process themes
             */
            themeStoreAPI.getState().actions.setAllThemes(response.data.themes)

            /**
             * Process global sets
             */
            globalSetStoreAPI.getState().actions.setAllGlobalSets(response.data.globalSets)

            /**
             * Process helps
             */
            helpStoreApi.getState().actions.setAllHelps(response.data.helps)

            /**
             * Process collectables
             */
            useCollectableStoreApi.getState().actions.setAllCollectables(response.data.collectables)

            /**
             * Process livestreams
             */
            useLiveStreamStoreApi.getState().actions.setAllLivestreams(response.data.livestreams)

            /**
             * Preloading video
             */
            videoStoreApi.getState().actions.preloadVideos()

            set({ siteData: response })

            console.log('response', response);

        },
        setDropCookie: (v)=> {
            set(state => ({ dropCookie: v }));
        }
    }
}))
export default siteStore
export {siteStoreApi}
