import React, {memo} from "react";

const Preloader = () => {

    /*useEffect(()=> {
        function getData() {
            async function get() {
                const data = await getAppData();
                return data
            }

            get().then(res => {
                // save the data in context
                // console.log('LOADED', res.data);

                const videos = {
                    twod: res.data.twod,
                    threed: res.data.threed
                }


            }).catch(err => {
                console.error('error', err);
            });
        }

        getData();

    }, [])*/

    return (
        <div>

        </div>
    )
};
export default memo(Preloader);
