import {query_scenes} from "./queries/scenes";
import {query_modals} from "./queries/modals";
import {query_videos} from "./queries/videos";
import {query_hotspots} from "./queries/hotspots";
import {query_icons} from "./queries/icons";
import {query_audio} from "./queries/audio";
import {query_routes} from "./queries/routes";
import {query_boomerangs} from "./queries/boomerangs";
import {query_galleries} from "./queries/galleries";
import {query_themes} from "./queries/themes";
import {query_globalSets} from "./queries/globalSets";
import {query_helps} from "./queries/helps";
import {query_collectables} from "./queries/collectables";
import {query_livestreams} from "./queries/livestreams";

export function getQueries() {
    return `query MyQuery { 
    ${query_scenes()},
    ${query_hotspots()},
    ${query_videos()},
    ${query_modals()},
    ${query_icons()},
    ${query_audio()},
    ${query_routes()},
    ${query_boomerangs()},
    ${query_galleries()},
    ${query_themes()},
    ${query_globalSets()}
    ${query_helps()},
    ${query_livestreams()},
    ${query_collectables()}
     }`
}

export function buildQuery(query) {
    return `query MyQuery { 
        ${query}
     }`
}
