import React, {useEffect, useRef, useState} from 'react';
import * as THREE from 'three'
import {useFrame} from '@react-three/fiber'
import {modalStoreApi} from "../store/modals/modalStore";
import {modalTypeStoreApi} from "../experience/components/html/modal/Modal";
import {isDesktop, isMobile} from "react-device-detect";
import {isPortrait} from "./utilities";

export const useAnimationFrame = callback => {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef();
    const previousTimeRef = React.useRef();

    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            callback(deltaTime)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }

    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once
}


export function useTurntable() {
    const ref = React.useRef();
    useFrame(() => {
        if (ref.current) {
            ref.current.rotation.y += 0.01
        }
    })

    return ref
}

// https://www.joshwcomeau.com/snippets/react-hooks/use-toggle/
export function useToggle(initialValue = false) {
    const [value, setValue] = React.useState(initialValue);
    const toggle = React.useCallback(() => {
        setValue(v => !v);
    }, []);
    return [value, toggle];
}

// https://gist.github.com/gragland/b61b8f46114edbcf2a9e4bd5eb9f47f5#gistcomment-3577005
export function useKeyPress(keys, onPress) {
    keys = keys.split(' ').map((key) => key.toLowerCase())
    const isSingleKey = keys.length === 1
    const pressedKeys = useRef([])

    const keyIsRequested = (key) => {
        key = key.toLowerCase()
        return keys.includes(key)
    }

    const addPressedKey = (key) => {
        key = key.toLowerCase()
        const update = pressedKeys.current.slice()
        update.push(key)
        pressedKeys.current = update
    }

    const removePressedKey = (key) => {
        key = key.toLowerCase()
        let update = pressedKeys.current.slice()
        const index = update.findIndex((sKey) => sKey === key)
        update = update.slice(0, index)
        pressedKeys.current = update
    }

    const downHandler = ({key}) => {
        const isKeyRequested = keyIsRequested(key)
        if (isKeyRequested) {
            addPressedKey(key)
        }
    }

    const upHandler = ({key}) => {
        const isKeyRequested = keyIsRequested(key)
        if (isKeyRequested) {
            if (isSingleKey) {
                pressedKeys.current = []
                onPress()
            } else {
                const containsAll = keys.every((i) => pressedKeys.current.includes(i))
                removePressedKey(key)
                if (containsAll) {
                    onPress()
                }
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        window.addEventListener('keyup', upHandler)
        return () => {
            window.removeEventListener('keydown', downHandler)
            window.removeEventListener('keyup', upHandler)
        }
    }, [])
}

export function useScaling(element, resizeParent = false) {
    const requestRef = React.useRef()
    const previousTimeRef = React.useRef()

    const scale = (deltaTime) => {
        let winHeight = window.innerHeight
        let scale = winHeight / element.current.offsetHeight
        scale = scale <= 1 ? scale : 1
        //element.current.style.transform = `scale(${scale})`
        if (resizeParent) {
            // element.current.parentNode.style.width = `${element.current.offsetWidth}px`
        }
    }

    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            scale(deltaTime)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }
    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once
}

export function useModalScaling(element, closed) {
    if (element.current) {
        element.current.style.removeProperty("height")
    }

    const scale = (deltaTime) => {
        let scaleAmount = 3
        if ( modalTypeStoreApi.getState().fullscreenVideo ) {
            scaleAmount = 1
        } else {
            scaleAmount = 3
        }

        let winWidth = window.innerWidth
        let scale = (winWidth / scaleAmount) / element.current.offsetWidth
        scale = scale <= 1 ? scale : 1
        element.current.style.transform = `scale(${scale})`
        // console.log('scaling');
        if ( modalTypeStoreApi.getState().scaling ) {
            if (element.current.offsetWidth > element.current.offsetHeight) {
                /*console.log('window.innerHeight', window.innerHeight);
                console.log('scale',scale);
                element.current.style.height = `${window.innerHeight * scale}px`*/
            }
        }
        if ( modalTypeStoreApi.getState().fullScreenModal ) {
            element.current.style.height = `${window.innerHeight / scale}px`
        }
    }

    const scaleMobile = (deltaTime) => {
        // console.log('scaleMobile');
        let scaleAmount = 1

        let winWidth = window.innerWidth - 38
        let winHeight = window.innerHeight - 38
        let scale

        // scale = scale <= 1 ? scale : 1
        // console.log('element.current.offsetWidth', element.current.offsetWidth);

        // mobilewrapper

        if (isPortrait()) {
            scale = (winWidth / scaleAmount) / element.current.offsetWidth
        } else {
            scale = winHeight / element.current.offsetHeight
        }
        element.current.parentNode.parentNode.style.transform = `scale(${scale})`

        if ( modalTypeStoreApi.getState().fullScreenModal ) {
            element.current.style.height = `${window.innerHeight / scale}px`
        }
    }
    // console.log('isPortrait', isPortrait());
    if (element.current) {
        if (isDesktop && !isPortrait() ) {
            scale()
        } else {
            scaleMobile()
        }
    }
    /*window.addEventListener('resize', ()=> {

        if (isDesktop && !isPortrait() ) {
            scale()
        } else {
            setTimeout(()=> {
                //scaleMobile()
            }, 1000)
        }
    })
    window.addEventListener("orientationchange", (e)=> {
        setTimeout(()=> {
            scaleMobile()
        }, 3000)
    })*/


    const requestRef = React.useRef()
    const previousTimeRef = React.useRef()
    const animate = time => {
        if (previousTimeRef.current !== undefined) {
            if (closed) return
            if (isDesktop && !isPortrait() ) {
                scale()
            } else {
                scaleMobile()
            }
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }
    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once


}

export function useMenuScaling(element) {
    const requestRef = React.useRef()
    const previousTimeRef = React.useRef()
    const scale = () => {
            let winHeight = window.innerHeight
            let winWidth = window.innerWidth



            let menuHeight = element.current.querySelector(".menu__content").offsetHeight;

            // console.log('',  element.current.querySelector(".menu__content").offsetHeight);
            let scale = winHeight / menuHeight

            // element.current.querySelector(".menu__content").style.minHeight = `${winHeight * scale}px`

            // console.log('', scale);
            element.current.style.transform = `scale(${scale})`
            // element.current.style.width = `${ (winWidth/3) / scale}px`
            // console.log('element.current.offsetHeight', element.current.offsetHeight);
           /* console.log('element.current.offsetHeight', element.current.querySelector(".menu__content").offsetHeight);
            let scale = winHeight / element.current.querySelector(".menu__content").offsetHeight
            let parentWidth = element.current.parentNode.getBoundingClientRect().width
            scale = scale <= 1 ? scale : 1
            element.current.style.transform = `scale(${scale})`
            element.current.style.width = `${parentWidth / scale}px`*/

    }
    if (element.current) {
        scale()
    }


    /*const animate = time => {
        if (previousTimeRef.current !== undefined) {
            const deltaTime = time - previousTimeRef.current;
            scale(deltaTime)
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }
    React.useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);
    }, []); // Make sure the effect runs only once*/


    window.addEventListener('resize', scale)
}

export function useMenuScaling2(element, resizeParent = false) {
    const scale = () => {
        let winHeight = window.innerHeight

        let scale = winHeight / element.current.offsetHeight
        scale = scale <= 1 ? scale : 1
        element.current.style.transform = `scale(${scale})`
        if (resizeParent) {
            element.current.parentNode.style.width = `${ (33 / scale)}%`
        }
    }
    if (element.current) {
        scale()
    }
    window.addEventListener('resize', scale)
}

// Hook
export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}
