import {createContext, useRef, useContext} from "react"
import {useThree} from "@react-three/fiber"
import * as THREE from "three";
import gsap from "gsap";
import {map} from "../../../../utils/utilities";

const offsetContext = createContext(0)

function useSceneBlock(index, range) {
    // const { sections, api.getState() } = state
    const {size, viewport, camera} = useThree()
    const offset = useContext(offsetContext)
    const viewportWidth = viewport.width
    const viewportHeight = viewport.height
    const canvasWidth = viewportWidth
    const canvasHeight = viewportHeight
    const mobile = size.width < 700
    const margin = canvasWidth * (mobile ? 0.2 : 0.1)
    const contentMaxWidth = canvasWidth * (mobile ? 0.8 : 0.6)


    const cameraZ = camera.position.z;
    const planeZ = 0;
    const distance = cameraZ - planeZ;
    const aspect = viewportWidth / viewportHeight;
    const vFov = camera.fov * Math.PI / 180;
    const planeHeightAtDistance = 2 * Math.tan(vFov / 2) * distance;
    const planeWidthAtDistance = planeHeightAtDistance * aspect;
    const fullWidth = planeWidthAtDistance * 1;
    const fullHeight = planeHeightAtDistance * 1;
    const halfWidth = fullWidth / 2;
    const halfHeight = fullHeight / 2;

    const getPositionX = (v) => {
        let max = halfWidth / 2;
        let min = -halfWidth / 2;
        return map(v, 0, 1, min, max);
    }
    const getPositionY = (v) => {
        let max = halfHeight;
        let min = -halfHeight;
        return map(v, 0, 1, min, max);
    }

    const getMeshes = (v, deep) => {
        let allMeshes = [];
        v.traverse(function (node) {
            if (node instanceof THREE.Group) {
                if (deep) {
                    /**
                     * Found a group with children Array
                     */
                    getMeshes(node);
                }
            }
            if (node instanceof THREE.GridHelper) {
                console.log('', node);
            }
            if (node instanceof THREE.Mesh || node instanceof THREE.Points || node instanceof THREE.GridHelper) {
                if (node.userData.exclude) {
                    // console.log('excluding', node);
                } else {
                    allMeshes.push(node)
                }

            }
        });

        return allMeshes
    }

    const getHoverGroups = (v) => {
        if (!v) return
        let allHoverGroups = [];
        v.traverse(function (node) {
            if (node instanceof THREE.Group) {
                if (node.name === "hovergroup") {
                    allHoverGroups.push(node)
                }
            }
        });
        return allHoverGroups
    }

    const getHTMLElements = (v) => {
        if (!v) return
        let allHTMLElements = [];
        v.traverse(function (node) {
            if (node instanceof THREE.Group) {

                if (node.userData.htmlElement) {
                    allHTMLElements.push(node)
                }
            }
        });
        return allHTMLElements
    }

    const fadeInMeshes = (v, O) => {

        getMeshes(v).forEach(mesh => {
            if (!mesh.userData.savedOpacity) {
                mesh.userData.savedOpacity = mesh.material.opacity
            }
            mesh.material.opacity = 0;
            // console.log('', mesh.userData.savedOpacity);
            gsap.to(mesh.material, {duration: 1, opacity: mesh.userData.savedOpacity});
        })
    }

    const fadeOutMeshes = (v, delay = 0.5) => {
        getMeshes(v).forEach(mesh => {
            if (!mesh.userData.savedOpacity) {
                mesh.userData.savedOpacity = mesh.material.opacity
            }
            gsap.to(mesh.material, {duration: 0.3, delay: delay, opacity: 0});
        })
    }

    const fadeOutTimeout = useRef();

    /**
     * Main fading in and out
     * @param group
     * @param time
     */
    const fadeOutGroup = (group, time = 0.3, deep = true, delay = 0, additiveToNormal = false, alpha = 0) => {
        if (!group) return;

        getHTMLElements(group).forEach(htmlElement => {
            let item = document.querySelector(`.${htmlElement.userData.className}`);
            if (item) {
                gsap.killTweensOf(item);
                // gsap.to(item, {duration: time, autoAlpha: alpha, delay: delay, onComplete: () => item.style.display = "none"})
                gsap.to(item, {
                    duration: time,
                    alpha: alpha,
                    delay: delay,
                    onComplete: () => item.style.display = "none"
                })
            }
        })

        getHoverGroups(group).forEach(hoverGroup => {
            hoverGroup.scale.x = hoverGroup.scale.y = 0;
        });

        getMeshes(group, deep).forEach(mesh => {

            if (!mesh.userData.savedOpacity) {
                mesh.userData.savedOpacity = mesh.material.opacity
            }
            if (mesh.material instanceof THREE.ShaderMaterial) {
                if (additiveToNormal) {
                    mesh.material.blending = THREE.AdditiveBlending;
                }
                gsap.killTweensOf(mesh.material.uniforms.opacity)
                gsap.to(mesh.material.uniforms.opacity, {
                    duration: time, value: alpha, delay: delay, onComplete: () => {
                        mesh.material.blending = THREE.NormalBlending;
                    }
                })
            } else {
                mesh.material.transparent = true;
                gsap.killTweensOf(mesh.material)
                gsap.to(mesh.material, {duration: time, opacity: alpha, delay: delay})
            }
        })
        // clearTimeout(fadeOutTimeout.current);
        if (alpha === 0) {
            fadeOutTimeout.current = setTimeout(() => {
                group.visible = false;
            }, time * 1000)
        }
    }

    const fadeInGroup = (group, time = 0.3, delay = 0, deep = true, additiveToNormal = true) => {
        if (!group) return;
        getHoverGroups(group).forEach(hoverGroup => {
            hoverGroup.scale.x = hoverGroup.scale.y = 1;
        });
        getHTMLElements(group).forEach(htmlElement => {

            let item = document.querySelector(`.${htmlElement.userData.className}`);
            item.style.display = "flex";
            gsap.killTweensOf(item);
            gsap.to(item, {duration: time, autoAlpha: 1, delay: delay})
        })
        getMeshes(group, deep).forEach((mesh, index) => {
            if (!mesh.userData.savedOpacity) {
                mesh.userData.savedOpacity = mesh.material.opacity
            }
            if (mesh.material instanceof THREE.ShaderMaterial) {
                if (additiveToNormal) {
                    mesh.material.blending = THREE.AdditiveBlending;
                }
                gsap.killTweensOf(mesh.material.uniforms.opacity)
                gsap.to(mesh.material.uniforms.opacity, {
                    duration: time,
                    value: mesh.userData.savedOpacity,
                    delay: delay + (index * 0.1)
                })
            } else {
                gsap.killTweensOf(mesh.material)
                gsap.to(mesh.material, {duration: time, opacity: mesh.userData.savedOpacity, delay: 0})
            }
        })
        clearTimeout(fadeOutTimeout.current);
        group.visible = true;
        //group.position.z = 0;
    }


    const sceneProgress = useRef(0)
    const active = useRef(false);

    return {
        viewport,
        offset,
        viewportWidth,
        viewportHeight,
        canvasWidth,
        canvasHeight,
        mobile,
        margin,
        contentMaxWidth,
        fullWidth,
        fullHeight,
        halfWidth,
        halfHeight,
        sceneProgress,
        active,
        getPositionX,
        getPositionY,
        getMeshes,
        fadeInMeshes,
        fadeOutMeshes,
        fadeOutGroup,
        fadeInGroup
    }
}

export {useSceneBlock}
