import {query_actions} from "./actions";
import {siteStoreApi} from "../../store/siteStore";

export const query_boomerangs = () => `
  boomerangs: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on boomerangs_default_Entry {
      id
      title
      boomerangSkippable
      cameraOrientation {
        x
        y
        z
      }
      boomerangVideo {
        id
      }
      events: boomerangEvents {
        ... on boomerangEvents_dropdown_BlockType {
        event: boomerangEventsDropdown
          eventActions: children {
            ... on boomerangEvents_actions_BlockType {
              ${query_actions}
            }
          }
        }
      }
    }
  }
`
