import "./_menu.scss"
import close from "../../../../assets/icons/svg/close_menu.svg";
import help from "../../../../assets/menu/help.svg";
import map from "../../../../assets/menu/map.svg";
import uiStore from "../ui/uiStore";
import {useMenuScaling, useScaling, useWindowSize} from "../../../../utils/hooks";
import {memo, useEffect, useRef} from "react";
import globalSetStore from "../../../../store/globalSets/globalSetStore";
import Interweave from "interweave";
import sceneStore, {sceneStoreApi} from "../../../../store/scenes/sceneStore";
import helpStore from "../../../../store/help/helpStore";
import SVG from 'react-inlinesvg';
import gsap from "gsap";
import {debounce} from "debounce";
import {useDebouncedCallback} from "use-debounce";
import modalStore from "../../../../store/modals/modalStore";
import audioStore from "../../../../store/audio/audioStore";
import ico_twitter from "../../../../assets/share/twitter.svg"
import ico_facebook from "../../../../assets/share/logo-facebook.svg"
import ico_instagram from "../../../../assets/share/instagram.svg"
import ico_whatsapp from "../../../../assets/share/whatsapp.svg"
import ico_email from "../../../../assets/share/email.svg"
import ico_link from "../../../../assets/share/link.svg"
import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton, LinkedinIcon, LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";
import {trackEvent, TrackingLabels} from "../../../../tracking/Tracking";

const share_url = "http://preview.immersivevideoplayer.com/"

const ShareIcons = memo(() => {
    const globalSets = globalSetStore(state => state.globalSets)

    function copyClipboard() {
        navigator.clipboard.writeText(share_url)
    }

    return (
        <div className="menushare">
            {
                globalSets && globalSets.menu.share_twitter ?
                    <TwitterShareButton url={share_url}>
                        <SVG src={ico_twitter}/>
                    </TwitterShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_facebook ?
                    <FacebookShareButton url={share_url}>
                        <SVG src={ico_facebook}/>
                    </FacebookShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_whatsapp ?
                    <WhatsappShareButton url={share_url}>
                        <SVG src={ico_whatsapp}/>
                    </WhatsappShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_email ?
                    <EmailShareButton url={share_url}>
                        <SVG src={ico_email}/>
                    </EmailShareButton>
                    : null
            }
            {
                globalSets && globalSets.menu.share_link ?
                    <div onClick={copyClipboard}>
                        <SVG src={ico_link}/>
                    </div>
                    : null
            }
        </div>
    )
})

function Menu() {
    const actions = uiStore(state => state.actions)
    const helpActions = helpStore(state => state.actions)
    const toggleMenu = uiStore(state => state.toggleMenu)
    const globalSets = globalSetStore(state => state.globalSets)
    const scenes = sceneStore(state => state.allScenes)
    const currentScene = sceneStore(state => state.currentScene)
    const modalActions = modalStore(state => state.actions)
    const audioActions = audioStore(state => state.actions)
    const sfx = globalSetStore(state => state.sfx)
    const menuRef = useRef()
    const menuContainer = useRef()
    const menuContent = useRef()

    useEffect(() => {
        // console.log('toggleMenu', toggleMenu);
        let menu = menuRef.current.getBoundingClientRect().width

       /* if (toggleMenu) {
            gsap.to(menuContainer.current, {duration: 1, x: "100%"})
        } else {
            gsap.to(menuContainer.current, {duration: 1, x: "0%"})
        }*/
        if (toggleMenu) {
            menuContainer.current.style.visibility = "visible"
        }
        gsap.to(menuContainer.current, {duration: 1.1, ease: "expo.out", x: !toggleMenu ? menu : 0,
        onComplete: ()=> {
            if (!toggleMenu) {
                menuContainer.current.style.visibility = "hidden"
            }
        }
        })
    }, [toggleMenu])

    useEffect(() => {
        const scale = () => {
            // console.log('menu', toggleMenu);
        }
        window.addEventListener('resize', scale)
        // scale()
    }, [])

    function onMenu() {
        audioActions.playAudioByID(sfx.sfxClick)
        actions.toggleMenu()
    }

    useMenuScaling(menuRef);

    function onMapClick(sceneID) {
        audioActions.playAudioByID(sfx.sfxClick)
        modalActions.setCloseModal(true)
        sceneStoreApi.getState().actions.setScene(sceneID)
        actions.toggleMenu()

        // tracking
            trackEvent(
                {
                    name: TrackingLabels.names.NAVIGATE_MENU,
                    category: TrackingLabels.categories.UI,
                    label: `scene_${sceneStoreApi.getState().actions.getScene(sceneID).title}`
                }
            )
        // end tracking
    }

    function getActive(id) {
        if (!currentScene) return
        if (id === currentScene.id) {
            return "menu__item--active"
        } else {
            return ''
        }
    }

    const debouncedHelp = useDebouncedCallback(
        () => {
            audioActions.playAudioByID(sfx.sfxClick)
            helpActions.getHelp()
            actions.toggleMenu()
        },
        2000,
        {leading: true}
    );

    // menu__container
    return (
        <>
            <div className={`menu__container`} ref={menuContainer}>
                <div className="menu__close ui__icon" onClick={onMenu}>
                    <SVG src={close}/>
                </div>
                {
                    globalSets && globalSets.menu.menuShowHelp ?
                        <div className="menu__help ui__icon" onClick={debouncedHelp}>
                            <SVG src={help}/>
                        </div>
                        : null
                }
                <div className={`menu col-menu`} ref={menuRef}>

                    <div className={`menu__content`} ref={menuContent}>

                            {
                                globalSets && globalSets.menu.menuAbout && globalSets.menu.menuShowAbout ?
                                    <div className="headericon">
                                        <Interweave content={globalSets.menu.menuAbout}/>
                                        <SVG src={help}/>
                                    </div>
                                    : null
                            }
                            {
                                globalSets && globalSets.menu.menuShowMap ?
                                    <>
                                        {
                                            globalSets.menu.menuMapTitle ?
                                                <>
                                                <div className="menu__line"/>
                                                <div className="headericon">
                                                    <Interweave content={globalSets.menu.menuMapTitle}/>
                                                    <SVG src={map}/>
                                                </div>
                                                </>
                                                : null
                                        }

                                        {
                                            scenes && scenes.map((scene, index) => (
                                                scene.sceneMap ?
                                                    <div key={index}
                                                         className={`menu__item ${getActive(scene.id)}`}
                                                         onClick={() => {
                                                             onMapClick(scene.id)
                                                         }}>
                                                        <div className="menu__line menu__line--min"/>
                                                        <h3>{scene.title}</h3>
                                                        <Interweave content={scene.sceneDescription}/>
                                                    </div> : null
                                            ))
                                        }
                                    </>
                                    : null
                            }
                            {
                                globalSets && globalSets.menu.menuShowShare ?
                                    <>
                                        <div className="menu__line"/>
                                        <div className="headericon">
                                            <h1>SHARE</h1>
                                            <Interweave content={globalSets.menu.menuShare}/>
                                            <ShareIcons/>
                                        </div>
                                    </>
                                    : null
                            }
                            {
                                globalSets && globalSets.menu.menuDisclaimer && globalSets.menu.menuShowDisclaimer ?
                                    <>
                                        <div className="menu__line"/>
                                        <div className="headericon">
                                            <Interweave content={globalSets.menu.menuDisclaimer}/>
                                        </div>
                                    </>
                                    : null
                            }
                    </div>
                </div>
            </div>
            <div className={`menu__cover ${toggleMenu ? "menu__cover--open" : "menu__cover--closed"}`}
                 onClick={onMenu}/>
        </>
    )
}

export default Menu
