/**
 * This drops the PhenixRTS script if any livestreams are enabled
 */
import InnerHTML from "dangerously-set-html-content";
import useLiveStreamStore from "./livestreamStore";
const html = `
        <script src="https://dl.phenixrts.com/JsSDK/2022.0.latest/min/channels.js"></script>
`
function Edgecast() {
     const hasLoadedPhenixRTS = useLiveStreamStore(state => state.hasLoadedPhenixRTS);

     return (
         <>
              {
                   hasLoadedPhenixRTS ? <InnerHTML html={html}/> : null
              }
         </>
     )
}
export default Edgecast

