import {Canvas, useLoader, useThree} from "@react-three/fiber";
import { ResizeObserver } from '@juggle/resize-observer'; // safari polyfill
import * as THREE from 'three'
import {useRef, useState, Suspense, useEffect} from "react";
import {PositionalAudio} from "@react-three/drei"
// import DebugBox from "../../../debug/DebugBox";
import {Lights} from "../lights/Lights";
import Sphere360 from "../sphere360/Sphere360";
import CControls from "../controls/CControls";
import Hotspots from "../hotspots/Hotspots";
import Video2D from "../video2D/Video2D";
import {useKeyPress} from "../../../../utils/hooks";
import HotspotCreator from "./HotspotCreator";
import Postprocessing from "../effects/Postprocessing";
import DebugBox from "../../../debug/DebugBox";
import sound from "../../../../assets/2.mp3"
import FullScreenVideo from "../FullScreenVideo.js/FullScreenVideo";


function ThreeEnvironment() {
    // console.log('render');
    const [showHotspots, setShowHotspots] = useState(false);
    useKeyPress("h", () => {
        if (window.isDebug) {
            setShowHotspots(true)
        }
    })
    useKeyPress("j", () => {
        if (window.isDebug) {
            setShowHotspots(false)
        }
    })
    const canvasRef = useRef();

     function Sound({ url }) {
          const sound = useRef();
          const { camera } = useThree();
          const [listener] = useState(() => new THREE.AudioListener());
          const buffer = useLoader(THREE.AudioLoader, url);
          useEffect(() => {
               sound.current.setBuffer(buffer);
               sound.current.setRefDistance(1);
               sound.current.setLoop(true);
               sound.current.play();
               camera.add(listener);
               return () => camera.remove(listener);
          }, []);
          return <positionalAudio ref={sound} args={[listener]} />;
     }

    return (
        <Canvas sRGB={true} resize={{polyfill: ResizeObserver}} ref={canvasRef}
                camera={{far: 2300, position: [0, 0, -10.1], fov: 70 }}
                gl={{alpha: false, antialias: false}}
                onCreated={({gl}) => {
                    gl.toneMapping = THREE.NoToneMapping
                    gl.outputEncoding = THREE.sRGBEncoding
                }}>
            {/*<Stats showPanel={0} className="stats" />*/}
            <Lights />
            <Sphere360 />
            <Video2D />
            <FullScreenVideo />
            <Hotspots />
            {
                showHotspots ?
                    <HotspotCreator/>
                    : null
            }
            <color attach="background" args={[new THREE.Color("#000000").convertSRGBToLinear()]} />
            {/*<DebugBox />*/}
            <CControls />
            <Postprocessing />

             {/*<Suspense fallback={null}>
                  <mesh position={[-16.83747996939255,-0.0706681620342881,10.714771810528381]} visible={false}>
                       <boxBufferGeometry attach="geometry" />
                       <meshBasicMaterial attach="material" color="hotpink" />
                       <Sound url={sound} />
                  </mesh>
             </Suspense>*/}

             {/*<Suspense fallback={null}>
             <PositionalAudio
                 url={sound}
                 distance={1}
                 loop
             />
             </Suspense>*/}
        </Canvas>
    )
}
export default ThreeEnvironment
