import Interweave from "interweave";
import {useEffect} from "react";
import modalStore from "../../../../../store/modals/modalStore";
import uiStore from "../../ui/uiStore";
import useCollectableStore from "../../../../../store/collectables/collectableStore";
import create from "zustand";
import {findAllByKey} from "../../../../../utils/utilities";

const [collectableItemStore, collectableItemStoreApi] = create((set, get) => ({
    collectable: null,
    total: 0,
    collected: 0,
    indexEvents: [],
    collectableCopy: '',
    setTotal: (v) => set({total: v}),
    setCollected: (v) => set({collected: v}),
    setCollectable: (v) => set({collectable: v}),
    setIndexEvents: (v) => set({indexEvents: v}),
    setCollectableCopy: (v) => set({collectableCopy: v})
}))

function ModalCollectable({content}) {
    useEffect(()=> {

    }, [])

    const openModal = modalStore(state => state.openModal)
    const uiStoreActions = uiStore(state => state.actions)
    const currentCollectable = useCollectableStore(state => state.currentCollectable)
    const collectableActions = useCollectableStore(state => state.actions)
    const total = collectableItemStore(state => state.total)
    const setTotal = collectableItemStore(state => state.setTotal)
    const collected = collectableItemStore(state => state.collected)
    const setCollected = collectableItemStore(state => state.setCollected)
    const collectable = collectableItemStore(state => state.collectable)
    const setCollectable = collectableItemStore(state => state.setCollectable)
    const indexEvents = collectableItemStore(state => state.indexEvents)
    const setIndexEvents = collectableItemStore(state => state.setIndexEvents)
    const collectableCopy = collectableItemStore(state => state.collectableCopy)
    const setCollectableCopy = collectableItemStore(state => state.setCollectableCopy)


    useEffect(()=> {
        if (!collectable) return
        // console.log('collectable', collectable.collectable.indexevents[collectable.collectable.count]);
        // console.log('collectable.collectable.count', collectable.collectable);
        console.log('collectable', collectable.collectable);

        indexEvents.forEach((event, i) => {
            if (event.index === collectable.collectable.count) {
                if (event.copy) {
                    collectable.collectable.copy = event.copy
                }
            }
        })
        setTotal(collectable.collectable.total)
        setCollected(collectable.collectable.count)
        setIndexEvents(collectable.collectable.indexevents)
    }, [currentCollectable, collectable])


    useEffect(()=> {
        setCollectable(collectableActions.getCollectableByID(openModal.content.collectable))

        /*const c = collectableActions.getCollectableByID(openModal.content.collectable).collectable
        setCollectableCopy(c.copy)*/
        return ()=> {
            setCollectable(null)
            uiStoreActions.setToggleCollectable(false)
        }
    }, [openModal])

    return (
        <div className="modaldev__content">
            {
                collectable ?
                    <div>
                        <div className="stamp__container">
                            {[...Array(collected)].map((e, i) => (
                                    <div key={i}
                                         className={`stamp__item`}
                                    >
                                        <img src={collectable.collectable.assetOn} />
                                        <div className="stamp__inner stamp--active" />
                                    </div>
                                )
                            )} {[...Array(total-collected)].map((e, i) => (
                                <div key={i}
                                     className={`stamp__item`}
                                >
                                    <img src={collectable.collectable.assetOff} />
                                    <div className="stamp__inner" />
                                </div>
                            )
                        )}
                        </div>
                        {
                            collectable ?
                                <Interweave content={collectable.collectable.copy}/>
                                : null
                        }

                    </div>
                    : null
            }

        </div>
    )
}

export default ModalCollectable
