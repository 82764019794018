import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef} from "react";
import {useFrame} from "@react-three/fiber";
import "./CircleMaterial"
import gsap from "gsap";
import {hexToGLArray, map} from "../../../../../../utils/utilities";


const ShaderCircle = forwardRef(({
                                     scale = 1,
                                     position = [0, 0, 0],
                                     color = '#ffffff',
                                     alpha = 0.1,
                                     innerRadius = 0.3,
                                     outerRadius = 0.34,
                                     spin = true,
                                     animated = false,
                                     control,
                                     ...props
                                 }, ref) => {

    useImperativeHandle(
        ref,
        () => ({
            setProgress(v) {
                meshMaterial.current.uniforms.time.value = map(v, 0, 1, 0, 2.9999);
            },
            setOpacity(v) {
                meshMaterial.current.uniforms.opacity.value = v;
            },
            fadeOut() {
                gsap.to(meshMaterial.current.uniforms.opacity, {duration: 0.2, value: 0})
            },
            tint(color) {
                meshMaterial.current.uniforms.u_Color.value = hexToGLArray(color);
            },
            expand(color) {
                meshMaterial.current.uniforms.u_Color.value = hexToGLArray(color);
                gsap.to(thisRef.current.scale, {
                    duration: 0.5,
                    x: (-0.001 * scale * 1.4),
                    y: (-0.001 * scale * 1.4),
                    ease: 'back.out(3)'
                })
                gsap.to(thisRef.current.position, {duration: 0.5, z: 0});
            },
            contract(color) {
                meshMaterial.current.uniforms.u_Color.value = hexToGLArray(color);
                gsap.to(thisRef.current.scale, {
                    duration: 0.5,
                    x: (-0.001 * scale * 1),
                    y: (-0.001 * scale * 1),
                    ease: 'back.out'
                })
                gsap.to(thisRef.current.position, {duration: 0.5, z: 0});
            },
            getMesh() {
                return thisRef
            }
        })
    )


    const meshMaterial = useRef();
    const thisRef = useRef();
    useEffect(() => {
        switch (control) {
            case 'hide':
                // console.log('shader hide', );
                // meshMaterial.current.uniforms.alpha.value = 0;
                gsap.to(meshMaterial.current.uniforms.opacity, {duration: 1, value: 0, ease: 'exp-o.out'})
                break;
            case 'show':
                // console.log('shader show', );
                //thisRef.current.rotation.y = Math.PI / 2;
                //gsap.to (thisRef.current.rotation, {duration: 4, y: 0, ease: 'expo.out'})
                thisRef.current.scale.set([0, 0, 0]);
                gsap.to(meshMaterial.current.uniforms.alpha, {duration: 2, value: alpha})
                const targScale = 0.001 * scale;
                gsap.to(thisRef.current.scale, {
                    duration: 1,
                    x: targScale,
                    y: targScale,
                    z: targScale,
                    ease: "back.out(1.7)"
                })
                break;
            default:
        }
        // eslint-disable-next-line
    }, [control])
    useLayoutEffect(() => {
        meshMaterial.current.uniforms.u_Color.value = hexToGLArray(color);
        meshMaterial.current.uniforms.opacity.value = 0.01;
        meshMaterial.current.uniforms.u_InnerRadius.value = innerRadius;
        meshMaterial.current.uniforms.u_OuterRadius.value = outerRadius;
        // eslint-disable-next-line
    }, [])
    useFrame(({clock}) => {
        // thisRef.current.rotation.y += 0.01;
        meshMaterial.current.uniforms.time.value = 2.9999;
        if (animated) {
            meshMaterial.current.uniforms.time.value = clock.oldTime * 0.0004;
        }
    })

    return (
        <group rotation={[0, Math.PI, -0.2]} scale={[-1,1,1]}>
            <mesh
                {...props}
                ref={thisRef}
                userData={{savedOpacity: 0.3}}
                scale={[0.02 * scale, 0.02 * scale, 1]}
            >
                <planeBufferGeometry args={[128, 128, 1]}/>
                <circleMaterial attach="material" ref={meshMaterial}/>
            </mesh>

        </group>
    )
});


export default ShaderCircle;
