import {siteStoreApi} from "../../store/siteStore";

export const query_routes = () =>`
  routes: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on routes_default_Entry {
      id
      title
      boomerangSkippable
      routeEnd {
        id
      }
      routeVideo {
        id
      }
      cameraOrientation {
        x
        y
        z
      }
    }
  }
`
