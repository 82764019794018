import {query_actions} from "./actions";
import {siteStoreApi} from "../../store/siteStore";

export const query_collectables = () =>`
  collectables: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on collectables_default_Entry {
      id
      title
      collectableDescription
      collectTotal
      collectableCopy
      collectableImageCollected {
        assetOn: url
      }
      collectableImageUncollected {
        assetOff: url
      }
      events: collectableEvents {
        ... on collectableEvents_selectACollectableEvent_BlockType {
          event: collectableEventsDropdown
          eventActions: children {
            ... on collectableEvents_actions_BlockType {
              ${query_actions}
            }
          }
        }
      }
      collectableIndex: collectableIndexActions {
        ... on collectableIndexActions_chooseCollectionTotal_BlockType {
          index: collectableIndex
          indexActions: children {
            ... on collectableIndexActions_actions_BlockType {
              ${query_actions}
            }
            ... on collectableIndexActions_indexCopy_BlockType {
              indexCopy: collectableCopy
            }
          }
        }
      }
    }
  }
`
