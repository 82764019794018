import React from 'react';
import ReactDOM, {hydrate, render} from 'react-dom';
import './index.css';
import App from './App';
import {HashRouter, MemoryRouter} from 'react-router-dom';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<MemoryRouter>
        <App/>
    </MemoryRouter>, rootElement);
} else {
    render(<MemoryRouter>
        <App/>
    </MemoryRouter>, rootElement);
}
