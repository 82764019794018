import {query_actions} from "./actions";
import {siteStoreApi} from "../../store/siteStore";



export const query_scenes = () => `
  scenes: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on scenes_default_Entry {
      id
      title
      sceneDescription
      sceneMap
      sceneStates {
        ... on sceneStates_state_BlockType {
          sceneHandle
          id
          sceneStateDetails
          stateHelp {
            id
          }
          sceneStateHotspots {
            id
          }
          scene360 {
            id
          }
          sceneAudio {
            title
            id
          }
          cameraOrientation {
            x
            y
            z
          }
          events: children {
            ... on sceneStates_stateEvents_BlockType {
              event: stateEvents
              eventActions: children {
                ... on sceneStates_actions_BlockType {
                  ${query_actions} 
                }
              }
            }
          }
        }
      }
    }
  }
`
