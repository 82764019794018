import ThreeEnvironment from "./components/three/threeEnvironment/ThreeEnvironment";
import UI from "./components/html/ui/UI";
import SceneManager from "./components/three/SceneManager";
import Modal from "./components/html/modal/Modal";
import Menu from "./components/html/menu/Menu";

function Experience() {

    return (
        <>
            <UI/>
            <Modal />
            <ThreeEnvironment />
            <SceneManager />
            <Menu />
        </>
    )
}

export default Experience
