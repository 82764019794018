// audio store
import create from "zustand";
import {findAllByKey} from "../../utils/utilities";
import {themeStoreAPI} from "../themes/themeStore";

const [globalSetStore, globalSetStoreAPI] = create((set, get) => ({
    destination: 0,
    globalSets: null,
    sfx: null,
    actions: {
        setAllGlobalSets(globalSets) {
            // console.log(globalSets);

            /**
             * Themes
             */
            let themeID = findAllByKey(globalSets, 'themeID')[0]
            themeStoreAPI.getState().actions.setSelectedTheme(themeID)

            /**
             * Landing page
             */
            let landingPageCopy = findAllByKey(globalSets, 'landingPageCopy')[0]
            let landingPageTerms = findAllByKey(globalSets, 'landingPageTerms')[0]
            let landingPageBackground = findAllByKey(globalSets, 'landingPageBackground')[0]
            let landingPageButton = findAllByKey(globalSets, 'landingPageButton')[0]

            /**
             * Menu
             */
            let menuAbout = findAllByKey(globalSets, 'menuAbout')[0]
            let menuMapTitle = findAllByKey(globalSets, 'menuMapTitle')[0]
            let menuShare = findAllByKey(globalSets, 'menuShare')[0]
            let menuShareIcons = findAllByKey(globalSets, 'shareIcons')
            let menuDisclaimer = findAllByKey(globalSets, 'menuDisclaimer')[0]
            let arrMenuToggles = findAllByKey(globalSets, 'menuToggles')

            let share_twitter = menuShareIcons.includes("twitter")
            let share_facebook = menuShareIcons.includes("facebook")
            let share_instagram = menuShareIcons.includes("instagram")
            let share_whatsapp = menuShareIcons.includes("whatsapp")
            let share_email = menuShareIcons.includes("email")
            let share_link = menuShareIcons.includes("link")

            let menuShowHelp = arrMenuToggles.includes("Help")
            let menuShowAbout = arrMenuToggles.includes("About")
            let menuShowMap = arrMenuToggles.includes("Map")
            let menuShowShare = arrMenuToggles.includes("Share")
            let menuShowDisclaimer = arrMenuToggles.includes("Disclaimer")

            /**
             * Audio settings
             */
            let audioTransitionIn = findAllByKey(globalSets, 'audioTransitionIn')[0]
            let audioTransitionOut = findAllByKey(globalSets, 'audioTransitionOut')[0]
            let audioInCurve = findAllByKey(globalSets, 'audioInCurve')[0]
            let audioOutCurve = findAllByKey(globalSets, 'audioOutCurve')[0]
            let sfxClick = findAllByKey(globalSets, 'sfxClick')[0]
            let sfxClose = findAllByKey(globalSets, 'sfxClose')[0]

            /**
             * Metadata
             */
            let metaTitle = findAllByKey(globalSets, 'metaTitle')[0]
            let metaDescription = findAllByKey(globalSets, 'metaDescription')[0]
            let metaAuthor = findAllByKey(globalSets, 'metaAuthor')[0]
            let metaKeywords = findAllByKey(globalSets, 'metaKeywords')[0]
            let metaUrl = findAllByKey(globalSets, 'metaUrl')[0]
            let ogLocale = findAllByKey(globalSets, 'ogLocale')[0]
            let ogSiteName = findAllByKey(globalSets, 'ogSiteName')[0]
            let ogImage = findAllByKey(globalSets, 'ogImage')[0].url
            let favicon_16x16 = findAllByKey(globalSets, 'favicon')[0].favicon_16x16
            let favicon_32x32 = findAllByKey(globalSets, 'favicon')[0].favicon_32x32
            let favicon_36x36 = findAllByKey(globalSets, 'favicon')[0].favicon_36x36
            let favicon_114x114 = findAllByKey(globalSets, 'favicon')[0].favicon_114x114
            let favicon_120x120 = findAllByKey(globalSets, 'favicon')[0].favicon_120x120
            let favicon_144x144 = findAllByKey(globalSets, 'favicon')[0].favicon_144x144
            let favicon_152x152 = findAllByKey(globalSets, 'favicon')[0].favicon_152x152
            let favicon_180x180 = findAllByKey(globalSets, 'favicon')[0].favicon_180x180
            let favicon_192x192 = findAllByKey(globalSets, 'favicon')[0].favicon_192x192
            let favicon_256x256 = findAllByKey(globalSets, 'favicon')[0].favicon_256x256
            let favicon_384x384 = findAllByKey(globalSets, 'favicon')[0].favicon_384x384
            let favicon_48x48 = findAllByKey(globalSets, 'favicon')[0].favicon_48x48
            let favicon_57x57 = findAllByKey(globalSets, 'favicon')[0].favicon_57x57
            let favicon_60x60 = findAllByKey(globalSets, 'favicon')[0].favicon_60x60
            let favicon_72x72 = findAllByKey(globalSets, 'favicon')[0].favicon_72x72
            let favicon_76x76 = findAllByKey(globalSets, 'favicon')[0].favicon_76x76
            let favicon_96x96 = findAllByKey(globalSets, 'favicon')[0].favicon_96x96
            // console.log('ogImage', ogImage);

            /**
             * Experience
             */
            let skipButton = findAllByKey(globalSets, 'skipButton')[0].url
            let skipButtonTiming = findAllByKey(globalSets, 'skipButtonTiming')[0]
            let helpTiming = findAllByKey(globalSets, 'helpTiming')[0]
            let primaryCollectable = findAllByKey(globalSets, 'primaryCollectable')[0]
            let initialScene = findAllByKey(globalSets, 'initialScene')[0]


            /**
             * Cookie Consent
             */
            let cookieConsent = findAllByKey(globalSets, 'cookieConsentCopy')[0]

            /**
             * Tracking
             */
            let trackingCode = findAllByKey(globalSets, 'trackingCode')[0]
            console.log('trackingCode', trackingCode);
            /**
             * Edgecast
             */
            let edgecast_userID = findAllByKey(globalSets, 'userId')[0]
            let edgecast_apiKey = findAllByKey(globalSets, 'apiKey')[0]

            set(state => ({
                globalSets: {
                    themeID: themeID,
                    landingPageCopy: landingPageCopy,
                    landingPageTerms: landingPageTerms,
                    landingPageBackground: landingPageBackground,
                    landingPageButton: landingPageButton,
                    cookieConsent: cookieConsent,
                    trackingCode: trackingCode,
                    menu: {
                        menuAbout: menuAbout,
                        menuMapTitle: menuMapTitle,
                        menuShare: menuShare,
                        menuDisclaimer: menuDisclaimer,
                        menuShowHelp: menuShowHelp,
                        menuShowAbout: menuShowAbout,
                        menuShowMap: menuShowMap,
                        menuShowShare: menuShowShare,
                        menuShowDisclaimer: menuShowDisclaimer,
                        share_twitter: share_twitter,
                        share_facebook: share_facebook,
                        share_instagram: share_instagram,
                        share_whatsapp: share_whatsapp,
                        share_email: share_email,
                        share_link: share_link
                    },
                    audioSettings: {
                        audioTransitionIn: audioTransitionIn,
                        audioTransitionOut: audioTransitionOut,
                        audioInCurve: audioInCurve,
                        audioOutCurve: audioOutCurve,
                    },
                    meta: {
                        title: metaTitle,
                        metaDescription: metaDescription,
                        metaAuthor: metaAuthor,
                        metaKeywords: metaKeywords,
                        metaUrl: metaUrl,
                        ogLocale: ogLocale,
                        ogSiteName: ogSiteName,
                        ogImage: ogImage,
                        favicon: {
                            favicon_16x16: favicon_16x16,
                            favicon_32x32: favicon_32x32,
                            favicon_36x36: favicon_36x36,
                            favicon_114x114: favicon_114x114,
                            favicon_120x120: favicon_120x120,
                            favicon_144x144: favicon_144x144,
                            favicon_152x152: favicon_152x152,
                            favicon_180x180: favicon_180x180,
                            favicon_192x192: favicon_192x192,
                            favicon_256x256: favicon_256x256,
                            favicon_384x384: favicon_384x384,
                            favicon_48x48: favicon_48x48,
                            favicon_57x57: favicon_57x57,
                            favicon_60x60: favicon_60x60,
                            favicon_72x72: favicon_72x72,
                            favicon_76x76: favicon_76x76,
                            favicon_96x96: favicon_96x96
                        }
                    },
                    experience: {
                        skipButton: skipButton,
                        skipButtonTiming: skipButtonTiming,
                        helpTiming: helpTiming * 1000,
                        primaryCollectable: primaryCollectable,
                        initialScene: initialScene
                    },
                    edgecast: {
                        edgecast_userID: edgecast_userID,
                        edgecast_apiKey: edgecast_apiKey
                    }
                }
            }))

            set(state => ({ sfx: {
                    sfxClick: sfxClick ? sfxClick.id : null,
                    sfxClose: sfxClose ? sfxClose.id : null
                } }))
        }
    }
}))
export default globalSetStore
export {globalSetStoreAPI}
