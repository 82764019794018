import {query_actions} from "./actions";
import {siteStoreApi} from "../../store/siteStore";

export const query_hotspots = () => `
  hotspots: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on hotspots_default_Entry {
      id
      title
      hotspotHideOnClick
      hotspotChooseIcon {
        id
      }
      hotspotLocation {
        x
        y
        z
      }
      actionBuilderHotspots {
        ... on actionBuilderHotspots_hotspot_BlockType {
          children {
            ... on actionBuilderHotspots_actions_BlockType {
             ${query_actions}
            }
          }
        }
      }
    }
  }
`
