import {useEffect, useRef} from "react";
import gsap from "gsap"

function ModaliFrame({content}) {

    useEffect(()=> {
        console.log('', content);
        if (!content.enableBackground) {
            document.body.classList.add("removebg")
        }

        return ()=> {
            document.body.classList.remove("removebg")
        }
    }, [])

    return (
            <iframe src={content.iframeSrc} />
    )
}

export default ModaliFrame
