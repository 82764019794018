import {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef} from "react";
import {extend, useFrame, useThree} from "@react-three/fiber";
import CameraControls from "camera-controls";
import gsap from "gsap";
import * as THREE from "three";
import {useKeyPress} from "../../../../utils/hooks";
import create from "zustand";
import videoStore from "../../../../store/videos/videoStore";
CameraControls.install({ THREE })
extend({ CameraControls })
let cto
let canSetTarget = true;
export const [controlsStore, controlsStoreApi] = create((set, get) => ({
    target: 0,
    setTarget: (v, override) => {
        cto = setTimeout(()=> {
            canSetTarget = true
        },2000)
        if (canSetTarget) {
            canSetTarget = false
            // console.log('setting target', v);
            set({target: {t: v, cache: Date.now()}})
        } else {
            // console.log('cannot set target');
        }
    }
}))

const CControls = forwardRef((_, ref) => {
    const camera = useThree((state) => state.camera)
    const gl = useThree((state) => state.gl)
    const controls = useMemo(() => new CameraControls(camera, gl.domElement), [camera, gl.domElement]);
    const currentPos = useRef();
    const saveRotation = useRef();
    const newRotation = useRef();
    const target = controlsStore(state => state.target)
    const play2D = videoStore(state => state.play2D)

    useEffect(()=> {
        controls.enabled = !play2D
    }, [play2D])

    function normaliseAngle(angle) {
        // return Math.asin(Math.sin(angle))
        return Math.atan2(Math.sin(angle), Math.cos(angle));
    }

    useImperativeHandle(
        ref,
        () => ({

        }),
    )

    /**
     * Put controls and other components in the store for global access
     */
    useLayoutEffect(()=> {
        controls.addEventListener('controlstart', ()=> {
            gsap.killTweensOf(controls)
        })
        controls.mouseButtons.wheel = CameraControls.ACTION.NONE
        controls.mouseButtons.right = CameraControls.ACTION.NONE
        controls.touches.two = CameraControls.ACTION.NONE;
        controls.touches.three = CameraControls.ACTION.NONE;
    }, [controls])


    useFrame(()=> {
        // camera.updateProjectionMatrix();

    })


    let hs = [-317.8824753860765, -64.8430821415463, -231.15962858704887]

    useKeyPress("1", ()=> {
        _setHotspot(hs, false)
        // controls.rotateTo( Math.PI, Math.PI/2, true)
    })

    useEffect(()=> {
        if (!target) return
        // console.log('target', target)
        _setHotspot([parseFloat(target.t.x), parseFloat(target.t.y), parseFloat(target.t.z)])
    }, [target])

    /**
     * Array of coords to look at
     * @param pos
     * @private
     */
    // TODO:

    function _setHotspot(pos, ignoreCrazySpin=false) {
        currentPos.current = pos;
        saveRotation.current = {theta: controls.azimuthAngle, phi: controls.polarAngle};
        controls.setTarget(pos[0],pos[1],pos[2])
        newRotation.current = {theta: controls.azimuthAngle, phi: controls.polarAngle};

        controls.setTarget(0,0,0)

        if (Math.abs(normaliseAngle(saveRotation.current.theta) - normaliseAngle(newRotation.current.theta)) > Math.PI) {
            //console.log('', saveRotation.current.theta);
            //console.log('normalised', normaliseAngle(saveRotation.current.theta));
            let t = Math.PI + (saveRotation.current.theta % Math.PI)
            if (t < 1) {
                t = Math.PI
            }
            // console.log('spin 1', t);
            controls.rotateTo((t), normaliseAngle(saveRotation.current.phi), false)
            controls.rotateTo(normaliseAngle(newRotation.current.theta), normaliseAngle(newRotation.current.phi), false)
        }
        else {
            // console.log('spin 2', normaliseAngle(saveRotation.current.theta));
            controls.rotateTo(normaliseAngle(saveRotation.current.theta), normaliseAngle(saveRotation.current.phi), false)
            controls.rotateTo(normaliseAngle(newRotation.current.theta), normaliseAngle(newRotation.current.phi), true)
        }
    }

    return useFrame((state, delta) => controls.update(delta))
});
export default CControls
