import ShaderCircle from "./shadercircle/ShaderCircle";
import {Billboard} from "@react-three/drei";
import {extend, useFrame, useThree} from '@react-three/fiber'
import {Text} from "troika-three-text";
import {debounce} from "debounce";
import {useRef, memo, useState, useEffect, Suspense} from "react";
// import {actionsStoreApi} from "../../../../../store/actions/actionStore";
import * as THREE from "three";
import {doActions} from "../../../../../store/actionBuilderProcessing";
import Icon from "./icon/Icon";
import {cameraStore, controlsStore} from "../../controls/CControls";
import {experienceStore} from "../../SceneManager";
import audioStore from "../../../../../store/audio/audioStore";
import globalSetStore, {globalSetStoreAPI} from "../../../../../store/globalSets/globalSetStore";
import {Vector3} from "three";
// import {hotspotStoreApi} from "../../../../../store/hotspots/hotspotStore";
extend({Text});

/**
 *
 * @param hotspot
 * All we need here is the location xyz
 * https://stackoverflow.com/questions/45927235/change-color-of-an-svg-dynamically-and-apply-as-texture-in-three-js
 */
function IconHotspot({hotspot}) {

    const [actionsDisplay, setActionsDisplay] = useState()
    const [hasPos, setHasPos] = useState(false)
    const [hide, setHide] = useState(false)
    const [iconActive, setIconActive] = useState(false)
    const setTarget = controlsStore(state => state.setTarget)
    const is2D = experienceStore(state => state.is2D)
    const audioActions = audioStore(state => state.actions)
    const currentVolume = audioStore(state => state.currentVolume)
    const sfx = globalSetStore(state => state.sfx)
    const thisRef = useRef()
    const {camera} = useThree()

    /**
     * Global volume change livestream volume
     */
    useEffect(()=> {
        if (currentVolume) {
            hotspot.channel?.unmute()
        } else {
            hotspot.channel?.mute()
        }
    }, [currentVolume])

    useEffect(()=> {
        // console.log('ON', );
        setIconActive(true)
        hotspot.active = true;
        return ()=> {
            // console.log('Off', );
            setIconActive(false)
            hotspot.active = false;
        }
    }, [])

    useEffect(() => {
        if (!hotspot) return;
        let actions = [];
        hotspot.actions.forEach(action => {
            actions.push(`${Object.values(action)}`)
        })
        setActionsDisplay(hotspot.title)
        // console.log('',hotspot );

        if (!hotspot.icon.hotspotBillboard) {
            // console.log('hotspot.icon.hotspotBillboard', hotspot.icon.hotspotBillboard);
            // thisRef.current.quaternion.copy(camera.quaternion)

            setHasPos(true)
            thisRef.current.lookAt(new Vector3((0, 0, 0)))
        }
    }, [hotspot])

    const [hovered, setHover] = useState(false)
    useEffect(() => {
        document.body.style.cursor = hovered ? 'pointer' : 'auto';
        if (hovered) {
            document.body.classList.add('hOver')
        } else {
            document.body.classList.remove('hOver')
        }
    }, [hovered]);

    useEffect(() => {
        if (is2D) {
            setHover(false)
        }
    }, [is2D])

    const onClick = () => {
        // check hide on click
        if (hotspot.hotspotHideOnClick) {
            setHide(true)
        }
        // sfx
        audioActions.playAudioByID(sfx.sfxClick)
        // move camera
        setTarget(hotspot.hotspotLocation[0])
        // actions
        doActions(hotspot.actions)

    }

    useFrame(() => {
        if (hotspot.icon.hotspotBillboard)
        thisRef.current.quaternion.copy(camera.quaternion)
    })

    return (
        <group position={getCoords(hotspot)} scale={5} ref={thisRef}>
                    <group
                        onPointerOver={(event) => setHover(true)}
                        onPointerOut={(event) => setHover(false)}
                        onClick={debounce(onClick, 200)}>
                        <Suspense fallback={null}>
                            <Icon hotspot={hotspot}/>
                        </Suspense>
                    </group>

        </group>
    )
}


export default IconHotspot

function getCoords(hotspot) {
    // console.log('', hotspot);
    return (
        [
            parseFloat(hotspot.hotspotLocation[0].x),
            parseFloat(hotspot.hotspotLocation[0].y),
            parseFloat(hotspot.hotspotLocation[0].z)
        ]
    )
}
