import {siteStoreApi} from "../../store/siteStore";

export const query_helps = () =>`
  helps: entries(site: "${siteStoreApi.getState().siteHandle}") {
    ... on help_default_Entry {
      id
      helpModalGroup {
        id
      }
    }
  }
`
